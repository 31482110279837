import { Container, Divider } from "@material-ui/core";
import { Add, Settings, VisibilityOutlined } from "@material-ui/icons";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import {
  AutoCompleteComponent,
  BalanceCard,
  CurrencyDisplay,
  ModalError,
  ModalWarning,
  MonthYearRangePicker,
  Page,
  PrimaryButton,
  PrimaryIconButton,
  SearchTextInput,
  SecondaryButton,
  SelectInput,
  SkeletonComponent,
} from "components";
import { InnoTableV2 } from "inno-ui";
import { HeaderTitle } from "layouts";
import { getTotalPage } from "lib";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { globalStyles } from "styles";
import Swal from "sweetalert2";
import { formatPeriodDate, getErrors, textCapitalization } from "utils";
import updateIcon from "../../../assets/img/pencilVector.svg";
import DeleteIcon from "../../../assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../services/urlConstant";

const ClaimAndUsage = () => {
  const classes = globalStyles();
  const location = useLocation();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const urlParams = new URLSearchParams(location.search);
  const paramsSearch = urlParams.get("search");
  const paramsPage = urlParams.get("page");
  const paramsSize = urlParams.get("size");
  const paramsStatus = urlParams.get("status");
  const paramsDSP = urlParams.get("dsp_id");
  const paramsStart = urlParams.get("start_date");
  const paramsEnd = urlParams.get("end_date");

  const [optionDSP, setOptionDSP] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [queryParams, setQueryParams] = useState({
    dsp_id: Number(paramsDSP) || "",
    page: Number(paramsPage) || 1,
    size: Number(paramsSize) || 10,
    search: paramsSearch || "",
    status: paramsStatus || "",
    start_date: paramsStart ? getFilterDate(paramsStart) : null,
    end_date: paramsEnd ? getFilterDate(paramsEnd) : null,
  });
  const [tableTotalPage, setTableTotalPage] = useState(1);
  const [dataSummary, setDataSummary] = useState({});

  const handleChangePageParams = (value, key) => {
    urlParams.set(key, value);
    history.push({ search: urlParams.toString() });
  };
  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
    handleChangePageParams(value, key);
    if (key !== "page") {
      handleChangePageParams(1, "page");
    }
  };

  const handleChangeStartDate = date => {
    handleChangeQueryParams(date, "start_date");
    handleChangePageParams(formatFilterDate(date), "start_date");
  };
  const handleChangeEndDate = date => {
    handleChangeQueryParams(date, "end_date");
    handleChangePageParams(formatFilterDate(date), "end_date");
  };

  const getClientList = async () => {
    await axios
      .get(`${hardBaseUrl}/master/all-client`, { headers })
      .then(res => {
        const { data } = res?.data;
        const modifiedData = data?.map(item => ({
          ...item,
          id: item?.dsp_id,
          label: item?.dsp_name,
        }));
        setOptionDSP(modifiedData);
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingTable(false));
  };
  const getDataSummary = () => {
    const url = `${hardBaseUrl}/publisher/transaction/summary/claim`;
    axios
      .get(url, {
        headers,
        ...(queryParams?.dsp_id && {
          params: { dsp_id: queryParams?.dsp_id || "" },
        }),
      })
      .then(res => {
        setDataSummary(res.data.data);
      })
      .catch(() => {});
  };
  const getDataTable = () => {
    setLoadingTable(true);
    axios
      .get(`${hardBaseUrl}/publisher/transaction/claim`, {
        headers,
        params: queryParams,
      })
      .then(res => {
        const { data, meta } = res?.data;
        setDataTable(data);
        const pageCount = getTotalPage(meta?.total, queryParams?.size || 1);
        setTableTotalPage(pageCount);
      })
      .catch(error => {
        ModalError(getErrors(error?.response));
      })
      .finally(() => setLoadingTable(false));
  };
  const debounceDataTable = useCallback(
    debounce(() => {
      getDataTable();
    }, 500),
    [queryParams]
  );
  const handleDelete = item => {
    axios
      .delete(`${hardBaseUrl}/publisher/transaction/${item}`, { headers })
      .then(res => {
        if (res.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Data berhasil dihapus",
          }).then(result => {
            if (result.isConfirmed === true) {
              debounceDataTable();
            }
          });
        }
      })
      .catch(() => {});
  };

  const TableActionButton = ({ tooltipTitle, link, icon, onClick, item }) => (
    <Grid item>
      <PrimaryIconButton
        icon={icon}
        onClick={
          link
            ? () =>
                history.push(link, {
                  customer: item,
                })
            : onClick
        }
        tooltipTitle={tooltipTitle}
      />
    </Grid>
  );
  const renderAction = item => {
    const { dsp_name, publisher_transaction_id, status } = item;
    const isPaid = status === "paid";
    const previewLink = `/admin/invoice/${publisher_transaction_id}`;
    const editLink = `/admin/invoice/edit/${publisher_transaction_id}`;

    return (
      <Grid container columnSpacing={1} width={120}>
        <TableActionButton
          item
          tooltipTitle="View"
          link={previewLink}
          icon={<VisibilityOutlined />}
        />
        {!isPaid && (
          <TableActionButton
            item
            tooltipTitle="Update"
            link={editLink}
            icon={<img src={updateIcon} alt="update-icon" />}
          />
        )}
        {!isPaid && (
          <TableActionButton
            item
            tooltipTitle="Delete"
            icon={<img src={DeleteIcon} alt="delete-icon" />}
            onClick={() =>
              ModalWarning(
                `Are you sure you want to delete this data: ${dsp_name}`,
                "Delete Claim"
              ).then(
                res =>
                  res?.isConfirmed && handleDelete(publisher_transaction_id)
              )
            }
          />
        )}
      </Grid>
    );
  };

  useEffect(() => {
    getClientList();
  }, []);
  useEffect(() => {
    const isSearching = Boolean(queryParams?.search);
    if (isSearching) {
      debounceDataTable();
      return () => {
        debounceDataTable.cancel();
      };
    } else {
      getDataTable();
      getDataSummary();
    }
  }, [queryParams, debounceDataTable]);

  return (
    <Page className={classes.root} title="Invoice">
      {loadingTable ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Invoice" breadcrumbData={breadcrumbData} />
          <Divider className={classes?.divider} />
          <AutoCompleteComponent
            label={queryParams?.dsp_id === "" ? "All Client" : "Client"}
            options={optionDSP}
            value={
              optionDSP.find(option => option.id === queryParams?.dsp_id) ||
              null
            }
            onChange={value => handleChangeQueryParams(value, "dsp_id")}
            size="small"
          />
          <Box mt="16px">
            <BalanceCard
              value={dataSummary?.revenue}
              clientLabel={dataSummary?.dsp_name}
            />
          </Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <SearchTextInput
                    placeholder="Search"
                    value={queryParams?.search}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "search")
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <MonthYearRangePicker
                    label="Period"
                    startDate={queryParams?.start_date}
                    handleChangeStartDate={handleChangeStartDate}
                    endDate={queryParams?.end_date}
                    handleChangeEndDate={handleChangeEndDate}
                  />
                </Grid>
                <Grid item>
                  <SelectInput
                    label="Status"
                    placeholder="None"
                    value={queryParams?.status}
                    onChange={e =>
                      handleChangeQueryParams(e?.target?.value, "status")
                    }
                    options={listStatus}
                    optionKey="value"
                    optionLabel="name"
                    width={200}
                  />
                </Grid>
                <Grid item>
                  <SecondaryButton
                    label="Invoice Setting"
                    onClick={() => history.push("/admin/konfigurasi/invoice")}
                    size="large"
                    startIcon={<Settings />}
                  />
                </Grid>
                <Grid item>
                  <PrimaryButton
                    label="Add Invoice"
                    onClick={() => history.push("/admin/invoice/add")}
                    size="large"
                    startIcon={<Add />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className={classes?.divider} />
          {dataTable && (
            <InnoTableV2
              isLoading={false}
              columns={columnTable}
              items={dataTable}
              page={queryParams?.page}
              rowsPerPage={queryParams?.size}
              totalPage={tableTotalPage}
              handleChangePage={(_, page) =>
                handleChangeQueryParams(page, "page")
              }
              handleChangeRowsPerPage={e =>
                handleChangeQueryParams(e?.target?.value, "size")
              }
              isHaveAction
              renderAction={renderAction}
            />
          )}
        </Container>
      )}
    </Page>
  );
};

const formatFilterDate = date => date.format("YYYY-MM-DD");
const getFilterDate = date => moment(date, "YYYY-MM-DD");
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Transaction",
    link: "/admin/invoice",
  },
  {
    label: "Incvoice",
    active: true,
  },
];
const listStatus = [
  {
    name: "Waiting for Approval",
    value: "waiting_for_approval",
  },
  {
    name: "Paid",
    value: "paid",
  },
  {
    name: "Unpaid",
    value: "unpaid",
  },
  {
    name: "Rejected",
    value: "rejected",
  },
];
const columnTable = [
  {
    name: "invoice_date",
    title: "Invoice Date",
  },
  {
    name: "transaction_number",
    title: "Invoice Number",
  },
  {
    name: "dsp_name",
    title: "Client Name",
  },
  {
    name: "all",
    title: "Period",
    renderText: item => {
      const [start, end] = item?.period?.split(" - ");
      const claim = item?.type === "claim";
      const startDate = moment(start).format("MMM YYYY");
      const endDate = moment(end).format("MMM YYYY");
      return item?.period
        ? claim
          ? `${startDate} - ${endDate}`
          : formatPeriodDate(start, end)
        : "-";
    },
  },
  {
    name: "type",
    title: "Type",
    renderText: item => textCapitalization(item),
  },
  {
    name: "exchange_rate",
    title: "Exchange Rate",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp" />
    ),
  },
  {
    name: "base_currency_revenue",
    title: "Base Currency Fee",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay value={item} decimalScale={2} prefix="Rp" />
    ),
  },
  {
    name: "all",
    title: "Origin Currency Fee",
    headerAlign: "right",
    renderText: item => (
      <CurrencyDisplay
        value={item?.original_currency_revenue}
        decimalScale={2}
        prefix={`${item?.currency_symbol_code}`}
      />
    ),
  },
  {
    name: "all",
    title: "Status",
    renderText: item => {
      const status = item?.status;
      const statusUpdate = item?.status_update;
      if (statusUpdate === "rejected") {
        return "Rejected";
      } else if (status === "waiting_for_approval") {
        return "Waiting for approval";
      } else {
        return textCapitalization(status);
      }
    },
  },
];
export default ClaimAndUsage;
