import Swal from "sweetalert2";

const ModalSuccess = (message, title) =>
  Swal.fire({
    title: title ? title : "Success",
    text: message ? message : "Data has been saved",
    icon: "success",
    confirmButtonText: "OK",
  });

export default ModalSuccess;
