import {
  Backdrop,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  TextField,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { getCookie } from "utils/constants";
import { Page, SelectInput, PrimaryButton } from "components";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { getErrors } from "../../../../utils";
import { InnoTableV2 } from "inno-ui";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  inputField: {
    padding: 0,
    paddingLeft: "1rem",
    height: "44px",
  },
  btnSubmit: {
    backgroundColor: "black",

    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageTitle: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "32px",
  },
  pageDescription: {
    marginTop: "5px",
    color: "#8f8f8f",
    fontSize: "14px",
  },
  btnPreview: {
    backgroundColor: "black",
    marginLeft: "10px",
    color: "white",
    padding: "10px 28px",
    width: "106px",
    height: "40px",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  label: {
    color: "#364052",
    fontSize: "14px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: 700,
    color: " #111827",
  },
  card: {
    color: "#9AA2B1",
    border: "1px solid #9AA2B1",
    borderRadius: "6px",
    boxShadow: "none",
    padding: 10,
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  textDis: {
    "& input.Mui-disabled": {
      color: "black",
    },
  },
  songDetail: {
    marginTop: 10,
  },
  required: {
    color: "red",
  },
}));

function EditPerformer(props) {
  const classes = useStyles();
  const history = useHistory();

  const [name, setName] = useState("");
  const [songList, setSongList] = useState([]);
  const [publisherId, setPublisherId] = useState("");
  const [optionOriginalPublisher, setOptionOriginalPublisher] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const [getColor, setGetColor] = useState("");
  const [nik, setNik] = useState("");
  const performerId = props.match.params.id;
  const userLogin = getCookie("auth", `${process.env.REACT_APP_NAME}_user`);
  const isAllowed = userLogin?.role?.type === "association";
  const getOptionPublisher = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/publisher`;
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);
      const { data } = res?.data;
      const modifiedData = [
        {
          publisher_id: "",
          name: "None",
        },
        ...(data || []),
      ];
      setOptionOriginalPublisher(modifiedData);
    } catch (error) {
      new Error(error);
    }
  };

  const fetchPerformers = async () => {
    try {
      let baseOfUrl = hardBaseUrl;
      const url = `${baseOfUrl}/publisher/performer/${performerId}`;
      let theToken = localStorage.getItem("token");
      const options = {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      };

      const response = await axios.get(url, options);

      setNik(response?.data?.data?.nik);
      setName(response?.data?.data?.first_name);
      setPublisherId(response?.data?.data?.publisher_id || "");
      setSongList(response?.data?.data?.song_performer || []);
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };
  const getMainDsp = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res?.data?.data?.publisher?.theme_color);
    } catch (e) {
      new Error(e);
    }
  };

  useEffect(() => {
    getMainDsp();
    fetchPerformers();
    getOptionPublisher();
  }, []);

  const handleChangeNik = event => {
    const maxValue = event?.target?.value?.length <= 16;
    if (maxValue) {
      setNik(event?.target?.value);
    }
  };
  const handleChangeName = item => {
    setName(item?.target?.value);
  };

  const handleButtonSimpan = async () => {
    setLoadingPage(true);
    let payload = {
      first_name: name,
      publisher_id: publisherId,
      nik: nik,
      is_active_flag: true,
    };
    let baseOfUrl = hardBaseUrl;
    let urlUse = baseOfUrl + "/publisher/performer/" + performerId;
    try {
      let theToken = localStorage.getItem("token");
      let resultAdd = await axios.put(urlUse, payload, {
        headers: {
          Authorization: "Bearer " + theToken,
        },
      });

      if (
        resultAdd.data.message === "success" &&
        resultAdd.data.meta.http_status === 200
      ) {
        setLoadingPage(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Success.",
          showConfirmButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            history.push("/admin/parameter/performer");
          }
        });
      }
    } catch (err) {
      let errMessage = getErrors(err.response);
      Swal.fire({
        title: "Oops…",
        text:
          err.response.data.errors.message === "NIK already exists"
            ? `NIK ${nik} already exists`
            : errMessage,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      setLoadingPage(false);
    }
  };

  return (
    <Page className={classes.root} title="Edit Performer">
      <Container maxWidth={false}>
        <Box justifyContent="space-between" display="flex">
          <Typography color="textPrimary" className={classes.pageTitle}>
            Edit Performer
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              color="textPrimary"
              className={classes.breadCrumbsActive}
            >
              Home
            </Typography>
            <Typography
              color="textPrimary"
              className={classes.breadCrumbsActive}
            >
              Parameter
            </Typography>
            <Typography
              color="textPrimary"
              className={classes.breadCrumbsActive}
            >
              Performer
            </Typography>
            <Typography color="textPrimary" className={classes.breadcrumbs}>
              Edit Performer
            </Typography>
          </Breadcrumbs>
        </Box>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subTitle}>
              Performer Information
            </Typography>
            <Typography className={classes.pageDescription}>
              To edit Performer data.
            </Typography>
          </Grid>
          {loadingPage ? (
            <Backdrop className={classes.backdrop} open={loadingPage}>
              <CircularProgress color="inherit" />
            </Backdrop>
          ) : (
            <Grid item xs={12} sm={8} md={9}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={3} className={classes.containerForm}>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" gridGap={2}>
                        <Typography className={classes.label}>NIK</Typography>
                      </Box>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        inputProps={{
                          className: classes.inputField,
                        }}
                        value={nik}
                        onChange={handleChangeNik}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" gridGap={2}>
                        <Typography className={classes.label}>
                          Performer Name
                        </Typography>
                        <Typography variant="span" className={classes.required}>
                          *
                        </Typography>
                      </Box>
                      <TextField
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          className: classes.inputField,
                        }}
                        value={name}
                        onChange={handleChangeName}
                      />
                    </Grid>
                    {isAllowed && (
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" gridGap={2}>
                          <Typography className={classes.label}>
                            Publisher
                          </Typography>
                          <Typography
                            variant="span"
                            className={classes.required}
                          >
                            *
                          </Typography>
                        </Box>
                        <SelectInput
                          value={publisherId}
                          onChange={e => setPublisherId(e?.target?.value)}
                          options={optionOriginalPublisher}
                          optionKey="publisher_id"
                          optionLabel="name"
                          width="100%"
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} sm={8} md={9} className={classes.songDetail}>
            <Box marginBottom={2}>
              <Typography
                component="h1"
                variant="h3"
                className={classes.subTitle}
              >
                Song Detail
              </Typography>
              <Typography className={classes.pageDescription}>
                See song detail&apos;s of Performer
              </Typography>
            </Box>
            <Box width="100%">
              <InnoTableV2
                items={songList}
                isLoading={false}
                columns={[
                  {
                    title: "Song",
                    name: "all",
                    renderText: item => {
                      return (
                        <Box
                          hover
                          cursor="pointer"
                          onClick={() =>
                            history.push({
                              pathname: `/admin/parameter/lagu/edit/${item?.song_id}`,
                              state: {
                                customer: item,
                              },
                            })
                          }
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.bodyTextBlack}
                          >
                            {item.song_title}
                          </Typography>
                        </Box>
                      );
                    },
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Box marginTop="1rem">
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <PrimaryButton
                    color="white"
                    textColor="black"
                    height={40}
                    width="106px"
                    variant="contained"
                    onClick={() => history.push("/admin/parameter/performer")}
                  >
                    Cancel
                  </PrimaryButton>
                </Grid>
                <Grid item>
                  <PrimaryButton
                    className={classes.btnPreview}
                    height={40}
                    variant="contained"
                    color={getColor || "black"}
                    textColor="white"
                    width="106px"
                    onClick={handleButtonSimpan}
                    disabled={loadingPage}
                  >
                    Save
                  </PrimaryButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default EditPerformer;
