import { Container, Divider } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { TreeItem, TreeView } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { Box, Grid, styled } from "@mui/material";
import axios from "axios";
import {
  CheckboxInput,
  FormLabel,
  ModalError,
  ModalSuccess,
  Page,
  PrimaryButton,
  SectionLabel,
  SelectInput,
  SkeletonComponent,
  TextInput,
} from "components";
import { HeaderTitle } from "layouts";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router";
import { getErrors, wordCapitalize } from "utils";
import { hardBaseUrl } from "../../../../services/urlConstant";
import Swal from "sweetalert2";

function FormRole() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer " + token,
  };
  const { role_id, role_type, type, selected_app } = useParams();
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [payload, setPayload] = useState({
    role_name: "",
    role_type,
    application: "caris",
    application_menus: [],
  });
  const isType = payload.application === "caris" ? 0 : 1;
  const handleChangePayload = (value, key) => {
    setPayload(prev => ({ ...prev, [key]: value }));
  };
  const handleParentMenuTextChange = (parentIndex, newText) => {
    let menus = payload?.application_menus[0].menus;
    if (payload.application === "caris") {
      menus = payload?.application_menus[0].menus;
    }
    setPayload(prev => {
      const updatedMenus = [...prev.menus];
      updatedMenus[parentIndex] = {
        ...updatedMenus[parentIndex],
        menu_text: newText,
      };
      return { ...prev, [menus]: updatedMenus };
    });
  };
  const handleChildMenuTextChange = (parentIndex, childIndex, newText) => {
    let menus = payload?.application_menus[0].menus;
    if (payload.application === "caris") {
      menus = payload?.application_menus[0].menus;
    }
    setPayload(prev => {
      const updatedMenus = [...prev.menus];
      const updatedChildren = [...updatedMenus[parentIndex].children];
      updatedChildren[childIndex] = {
        ...updatedChildren[childIndex],
        menu_text: newText,
      };
      updatedMenus[parentIndex] = {
        ...updatedMenus[parentIndex],
        children: updatedChildren,
      };
      return { ...prev, [menus]: updatedMenus };
    });
  };

  const handleChildCheckboxChange = (parentOrder, childOrder) => {
    const newPermission = [...payload?.application_menus[0].menus];
    const updatedPermissions = [...newPermission]?.map(({ children, order, ...rest }) => {
      const newChildren = children?.map(child => ({
        ...child,
        is_active: child.order === childOrder ? !child?.is_active : child?.is_active
      }));
      return {
        ...rest,
        order,
        children: parentOrder === order ? newChildren : children,
        is_active: newChildren?.every(({ is_active }) => is_active),
      };
    });
    setPayload(prev => ({
      ...prev,
      application_menus: prev.application_menus.map(menu => ({
        ...menu,
        menus: updatedPermissions
      })),
    }));
  };


  const handleParentDragEnd = result => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const isParent = draggableId.startsWith("draggable-parent");
    const isChild = draggableId.startsWith("draggable-child");

    if (isParent) {
      const newPermissions = payload?.application_menus[isType].menus || [];
      const movedPermission = newPermissions.splice(source.index, 1)[0];
      newPermissions.splice(destination.index, 0, movedPermission);
      newPermissions.forEach((menu, index) => {
        menu.order = index + 1;
      });
      setPayload(prev => ({
        ...prev,
        application_menus: prev.application_menus.map((menu, index) =>
          index === isType ? { ...menu, menus: newPermissions } : menu
        ),
      }));
    } else if (isChild) {
      const parentIndex = parseInt(source.droppableId.split("-")[2]);
      const newPermissions = [...payload?.application_menus[isType].menus];
      const updatedParentPermission = { ...newPermissions[parentIndex] };
      updatedParentPermission.children = updatedParentPermission.children || [];
      const movedChild = updatedParentPermission.children.splice(
        source.index,
        1
      )[0];

      updatedParentPermission.children.splice(destination.index, 0, movedChild);
      updatedParentPermission.children.forEach((child, index) => {
        if (child) {
          child.order = index + 1;
        }
      });
      newPermissions[parentIndex] = updatedParentPermission;
      setPayload(prev => ({
        ...prev,
        application_menus: prev.application_menus.map((menu, index) =>
          index === isType ? { ...menu, menus: newPermissions } : menu
        ),
      }));
    }
  };
  const validatePayload = () => {
    const errors = [];
    const errorEmpty = error => `${error} can't be empty.`;
    const hasActiveChildren = payload?.application_menus[
      isType
    ].menus?.some(permission =>
      permission.children?.some(child => child?.is_active)
    );

    if (payload.role_name === "") {
      errors.push(errorEmpty("Role Name"));
    } else if (!hasActiveChildren) {
      errors.push(errorEmpty("Access"));
    }
    return errors;
  };

  const DragIcons = () => (
    <svg
      width="6"
      height="18"
      viewBox="0 0 10 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="2" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="8" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="14" r="2" fill="#D1D5DC" />
      <circle cx="2" cy="20" r="2" fill="#D1D5DC" />
      <circle cx="8" cy="20" r="2" fill="#D1D5DC" />
    </svg>
  );
  const renderPermission = (permission, parentIndex) => {
    const parentKey = permission.id !== 0 ? permission.id : permission.order;
    return (
      <Draggable
        key={`draggable-parent-${parentKey}`}
        draggableId={`draggable-parent-${parentKey}`}
        index={parentIndex}
        type="PARENT"
      >
        {provided => (
          <Grid
            container
            spacing={1}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Grid item>
              <Box my="6px">
                <DragIcons />
              </Box>
            </Grid>
            <Grid item>
              <TreeView
                defaultCollapseIcon={<IndeterminateCheckBoxIcon />}
                defaultExpandIcon={<AddBox />}
              >
                <TreeItem
                  nodeId={`tree-parent-${parentKey}`}
                  label={
                    <TextInput
                      variant="standard"
                      value={permission.menu_text}
                      onChange={e =>
                        handleParentMenuTextChange(parentIndex, e.target.value)
                      }
                      width={288}
                    />
                  }
                >
                  {permission.children?.length > 1 ? (
                    <Droppable
                      droppableId={`droppable-child-${parentIndex}`}
                      type="CHILD"
                      direction="vertical"
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {permission.children &&
                            permission.children.map((child, childIndex) =>
                              renderChild(
                                child,
                                childIndex,
                                permission,
                                parentIndex
                              )
                            )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ) : (
                    <CheckboxInput
                      checked={permission.children[0].is_active}
                      onChange={() =>
                        handleChildCheckboxChange(
                          permission?.order,
                          permission.children[0]?.order
                        )
                      }
                      label={
                        <TextInput
                          variant="standard"
                          value={permission.children[0].menu_text}
                          onChange={e =>
                            handleChildMenuTextChange(
                              parentIndex,
                              0,
                              e.target.value
                            )
                          }
                          width={250}
                        />
                      }
                      size="small"
                    />
                  )}
                </TreeItem>
              </TreeView>
            </Grid>
          </Grid>
        )}
      </Draggable>
    );
  };
  const renderChild = (child, childIndex, parentPermission, parentIndex) => {
    if (!child) {
      return null;
    }

    const parentKey =
      parentPermission.id !== 0 ? parentPermission.id : parentPermission.order;
    const childrenKey = child.id !== 0 ? child.id : child.order;
    return (
      <Draggable
        key={`draggable-child-${parentKey}-${childrenKey}`}
        draggableId={`draggable-child-${parentKey}-${childrenKey}`}
        index={childIndex}
        type="CHILD"
      >
        {provided => (
          <Grid
            container
            columnSpacing={1}
            alignItems="center"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Grid item pt="6px">
              <DragIcons />
            </Grid>
            <Grid item>
              <CheckboxInput
                checked={child.is_active}
                onChange={() =>
                  handleChildCheckboxChange(parentPermission?.order, child?.order)
                }
                label={
                  <TextInput
                    variant="standard"
                    value={child.menu_text}
                    onChange={e =>
                      handleChildMenuTextChange(
                        parentIndex,
                        childIndex,
                        e.target.value
                      )
                    }
                    width={250}
                  />
                }
                size="small"
              />
            </Grid>
          </Grid>
        )}
      </Draggable>
    );
  };

  const getAccessList = async () => {
    try {
      setLoadingPage(true);
      const { role_type } = payload;
      const res = await axios.get(`${hardBaseUrl}/role-menu`, {
        headers,
        params: {
          ...(role_id && { role_id: Number(role_id) }),
          role_type,
        },
      });
      const { role_name, application_menus } = res?.data?.data;
      const dataTemp = [];
      application_menus.map(value => {
        value.menus.forEach((menu, index) => {
          menu.order = index + 1;
          if (menu.children && menu.children.length > 0) {
            menu.children.forEach((child, childIndex) => {
              child.order = childIndex + 1;
            });
          }
        });
        dataTemp.push({
          application: value.application,
          menus: value.menus,
        });
      });
      setPayload({
        ...payload,
        role_name,
        application: selected_app || "caris",
        application_menus: dataTemp,
      });
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = async () => {
    const { role_name, application_menus } = payload;
    const mpisMenus =
      application_menus.find(item => item.application === "mpis")?.menus || [];
    const carisMenus =
      application_menus.find(item => item.application === "caris")?.menus || [];

    const isMpisActive = mpisMenus.some(menu => menu.is_active);
    const isCarisActive = carisMenus.some(menu => menu.is_active);
    const modifiedPayload = {
      ...(role_id && { role_id: Number(role_id) }),
      role_name,
      role_type,
      application_menus: application_menus,
    };
    const method = role_id ? axios.put : axios.post;
    const errors = validatePayload();

    if (errors?.length) {
      ModalError(errors[0]);
      return;
    }
    Swal.fire({
      title: "Confirmation",
      text: `Are you sure you want to ${role_id ? "save" : "update"} ${isMpisActive && isCarisActive ? "MPIS & CARIS" : ""
        } this data?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          setLoadingButton(true);
          await method(`${hardBaseUrl}/role-menu`, modifiedPayload, {
            headers,
          });
          ModalSuccess(
            `Successfully ${role_id ? "Save" : "Create"} Role`
          ).then(() => history.goBack());
        } catch (error) {
          ModalError(getErrors(error?.response));
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    getAccessList();
  }, []);
  return (
    <Page
      className={classes?.root}
      title={`${wordCapitalize(type)} ${wordCapitalize(role_type)} Role`}
    >
      {loadingPage ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle
            title={`${wordCapitalize(type)} ${wordCapitalize(role_type)} Role`}
            breadcrumbData={breadcrumbData({ type, role_type })}
            backButton
          />
          <Divider className={classes?.divider} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SectionLabel title="User" />
              <FormCard>
                <FormLabel label="Role Username" required />
                <TextInput
                  value={payload?.role_name}
                  onChange={event =>
                    handleChangePayload(event?.target?.value, "role_name")
                  }
                  placeholder="Username"
                />
                <Box height="16px" />
                <FormLabel label="App Access" required />
                <SelectInput
                  options={optionApplication}
                  optionKey="value"
                  optionLabel="label"
                  value={payload?.application}
                  onChange={e =>
                    handleChangePayload(e?.target?.value, "application")
                  }
                  disabled={role_type !== "publisher"}
                  width="100%"
                />
              </FormCard>
              <PrimaryButton
                label={
                  loadingButton
                    ? role_id
                      ? "Saving"
                      : "Adding"
                    : role_id
                      ? "Save"
                      : "Add"
                }
                onClick={handleSubmit}
                disabled={loadingButton}
                loading={loadingButton}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionLabel title="Access Rights" />
              <FormCard>
                <DragDropContext onDragEnd={handleParentDragEnd}>
                  <Droppable
                    droppableId="droppable-parent"
                    direction="vertical"
                  >
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {payload?.application_menus[0]?.menus.map(
                          (permission, parentIndex) =>
                            renderPermission(permission, parentIndex)
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </FormCard>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: ["Helvetica Neue"].join(","),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
}));
const FormCard = styled(Box)({
  border: "1px solid #9AA2B1",
  borderRadius: "8px",
  padding: "16px",
  margin: "16px 0",
});
const breadcrumbData = ({ type, role_type }) => [
  {
    label: "Configuration",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: "User Role",
    link: "/admin/konfigurasi/role-user",
  },
  {
    label: `${wordCapitalize(type)} ${wordCapitalize(role_type)} Role`,
    active: true,
  },
];
const optionApplication = [
  {
    value: "mpis",
    label: "MPIS",
  },
  {
    value: "caris",
    label: "CARIS",
  },
];
export default FormRole;
