import React, { useState, useEffect } from "react";
import { Page, SkeletonComponent } from "components";
import HeaderPage from "../../../../layouts/Header/HeaderPage";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { Add } from "@material-ui/icons";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import Swal from "sweetalert2";
import updateIcon from "assets/img/pencilVector.svg";
import deleteIcon from "assets/img/trashVector.svg";
import { hardBaseUrl } from "../../../../services/urlConstant";
import {
  Container,
  TextField,
  InputAdornment,
  Button,
  Typography,
  IconButton,
  FormControl,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { InnoTable } from "inno-ui";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    marginTop: "20px",
  },
  contentAdd: {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    fontFamily: ["Inter"].join(","),
  },
  logoColumn: {
    maxWidth: 50,
  },
  buttonAdd: {
    height: 44,
    width: "auto",
    backgroundColor: "black",
    color: "white",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  buttonDelete: {
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    marginLeft: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
  },
  buttonCancel: {
    color: "black",
    marginLeft: 10,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  buttonAction: {
    backgroundColor: "#111827",
    color: "white",
    width: "35px",
    height: "35px",
    padding: "6px",
    borderRadius: "6px",
    marginLeft: "10px",
    cursor: "pointer",
    transition: "all 0.2s ease-in",
    "&:hover, &.MuiIconButton": {
      background: "#374151 !important",
    },
  },
  selectPadding: {
    "& .MuiSelect-outlined": {
      paddingTop: "14px",
      paddingBottom: "14px",
      paddingLeft: "20px",
      paddingRight: "30px",
    },
  },
  inputFields: {
    height: "44px",
    paddingTop: "0px",
    paddingBottom: "0px",
    "& .MuiOutlinedInput-root": {
      minWidth: 250,
      height: "44px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  boxAddButton: {
    margin: "20px 0px",
  },
  containerModal: {
    padding: "1rem",
  },
}));

const columns = [
  {
    name: "society_name",
    title: "Society",
  },
  {
    name: "id",
    title: "ID Society",
  },
  {
    name: "caris_subdomain",
    title: "URL CARIS",
    renderText: caris_subdomain => (
      <Typography>{`${caris_subdomain}.caris.id`}</Typography>
    ),
  },
  {
    name: "email",
    title: "Email",
  },
  {
    name: "phone_number",
    title: "Phone",
  },
];

const ConfirmationDelete = ({ openModal, onClose, onSubmit }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={openModal}
      onClose={onClose}
      className={classes.containerModal}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">Delete Society</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography>
              Are you sure you want to delete this society?
            </Typography>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="end" gap={3}>
              <Button
                onClick={onClose}
                className={classes.buttonCancel}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                className={classes.buttonDelete}
                variant="outlined"
                onClick={onSubmit}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function Society() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [state, setState] = useState({
    value: {
      territory_code: "",
      territory_ext_code: "",
      country: [],
    },
    page: 1,
    search: "",
    size: 10,
    listSociety: [],
    totalData: 0,
    loading: false,
    openModalDelete: false,
    itemToDelete: null,
  });
  const {
    openModalDelete,
    page,
    search,
    size,
    listSociety,
    totalData,
    loading,
    itemToDelete,
  } = state;
  const openDialog = type =>
    setState(currentState => ({
      ...currentState,
      [type]: true,
    }));

  const closeDialog = type =>
    setState(currentState => ({
      ...currentState,
      [type]: false,
    }));

  const changeValue = (key, value) =>
    setState(currentState => ({
      ...currentState,
      value: {
        ...currentState.value,
        [key]: value,
      },
    }));

  const changeState = (key, value) =>
    setState(currentState => ({
      ...currentState,
      [key]: value,
    }));

  const deleteTerritory = id => {
    const url = `${hardBaseUrl}/admin/society/${id}`;
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: "This society successfully deleted",
          icon: "success",
          confirmButtonText: "Ok",
        });
        getlistSociety(page, size, search);
      })
      .catch(err => {
        Swal.fire({
          title: "Error",
          text: err,
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        changeValue("itemToDelete", null);
        closeDialog("openModalDelete");
      });
  };

  const getlistSociety = async (page, size, search) => {
    changeState("loading", true);
    const url = `${hardBaseUrl}/admin/society/`;
    const params = {
      page,
      per_page: size,
      search,
    };
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([, value]) => value)
    );
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filteredParams,
      });
      changeState("listSociety", response?.data?.data || []);
      changeState("totalData", response?.data?.meta?.total);
      changeState("loading", false);
    } catch (err) {
      Swal.fire({
        title: "Error",
        text:
          err?.response?.data?.errors[0]?.message ||
          err?.message ||
          "Something Wrong",
        icon: "error",
        confirmButtonText: "Ok",
      });
      changeState("loading", false);
    }
  };

  useEffect(() => {
    getlistSociety(page, size, search);
  }, []);

  useEffect(() => {
    getlistSociety(page, size, search);
  }, [page, size, search]);
  const pagesCount = Math.ceil(totalData / size);
  return (
    <Page className={classes.root} title="Society">
      <ConfirmationDelete
        openModal={openModalDelete}
        onClose={() => closeDialog("openModalDelete")}
        onSubmit={() => deleteTerritory(itemToDelete)}
      />
      <Container maxWidth={false}>
        <HeaderPage
          title="Parameter"
          breadcrumbs={["Parameter", "Society"]}
        />
        <ButtonGroupTop />
        <Box className={classes.content}>
          <Grid container className={classes.boxAddButton} justifyContent="space-between">
            <Grid item>
              <FormControl className={classes.margin} variant="outlined">
                <TextField
                  id="input-with-icon-textfield"
                  variant="outlined"
                  className={classes.inputFields}
                  onChange={e => changeState("search", e?.target?.value)}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          alt="Logo"
                          src={
                            require("assets/image-public/images/search.svg")
                              .default
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                onClick={() => history.push("/admin/parameter/society/add")}
                variant="contained"
                className={classes.buttonAdd}
                startIcon={<Add />}
              >
                Add Society
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <SkeletonComponent variant="wave" />
          ) : (
            <InnoTable
              idColumnName={"id"}
              page={page}
              handleChangePage={(_, page) => changeState("page", page)}
              handleChangeRowsPerPage={e =>
                changeState("size", e?.target?.value)
              }
              rowsPerPage={size}
              totalPage={pagesCount}
              isLoading={loading}
              columns={[
                {
                  name: "all",
                  title: "Action",
                  renderText: item => (
                    <Box display="flex">
                      <IconButton
                        onClick={() =>
                          history.push(
                            `/admin/parameter/society/edit/${item?.id}`
                          )
                        }
                        className={classes.buttonAction}
                      >
                        <img src={updateIcon} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          changeState("itemToDelete", item?.id);
                          openDialog("openModalDelete");
                        }}
                        className={classes.buttonAction}
                      >
                        <img src={deleteIcon} />
                      </IconButton>
                    </Box>
                  ),
                },
                {
                  name: "profile_image_url",
                  title: "Logo",
                  renderText: profile_image_url => (
                    <Box className={classes.logoColumn}>
                      <img
                        src={profile_image_url}
                        style={{ maxWidth: "100%" }}
                      />
                    </Box>
                  ),
                },
                ...columns,
              ]}
              items={listSociety}
            />
          )}

        </Box>
      </Container>
    </Page>
  );
}

export default Society;
