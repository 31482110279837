import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import ExampleLayout from "../../layouts/ExampleLayout";
import {
  KonfigurasiAdminDspTypeRevenue,
  KonfigurasiAdminDspTypeRevenueAdd,
  KonfigurasiAdminDspTypeRevenueEdit,
  DashboardAssoc,
  KonfigurasiAdminAktifitas,
  BillingKonfigurasi,
  KonfigurasiAdminPajak,
  KonfigurasiAdminRoleUser,
  FormRole,
  KonfigurasiAdminSistem,
  KonfigurasiAdminTemplateNotifikasi,
  KonfigurasiAdminMessage,
  KonfigurasiAdminMessageEdit,
  KonfigurasiAdminMessageAdd,
  FinalisasiBeritaIklanAdmin,
  PreviewBeritaIklanAdmin,
  BeritaIklanAdmin,
  KonfigurasiAdminAnnouncementManagement,
  KonfigurasiAdminAnnouncementManagementDetail,
  KonfigurasiAdminAnnouncementManagementAddOrEdit,
  MasterAdminBank,
  MasterAdminBankTambah,
  MasterAdminBankUbah,
  MasterAdminPublisher,
  MasterAdminUser,
  MasterAdminEditUser,
  MasterAdminTambahUser,
  MasterAdminLagu,
  MasterAdminLaguEdit,
  MasterAdminLaguTambah,
  MasterAdminPencipta,
  MasterAdminPenciptaEdit,
  MasterAdminUserPencipta,
  MasterAdminUserPublisher,
  MasterAdminCurrency,
  MasterAdminCurrencyTambah,
  MasterAdminCurrencyEdit,
  ManajemenWebAdmin,
  PreviewManajemenWebAdmin,
  ReviewLaguAssoc,
  DetailReviewLaguAssoc,
  Billing,
  BillingDsp,
  BillingDetail,
  EditDsp,
  TambahDsp,
  MasterAdminDsp,
  AddPublisher,
  EditPublisher,
  MasterAdminTerritory,
  MasterAdminAddEditTerritory,
  MasterAdminSociety,
  MasterAdminEditOrAddSociety,
  MasterAdminUserSociety,
  PrintReportDetailReviewAssoc,
  PrintReportReviewAssoc,
  MasterAssociation,
  AddAsosiasi,
  EditAsosiasi,
  SongUnclaim,
  FormTax,
  MasterCountry,
  MasterCountryCreateOrEdit,
  MasterRevenueType,
  MasterRevenueTypeCreateOrEdit,
  UsageProcess,
  PreviewFinalisasiBerita,
} from "pages/Admin";

// =========== Composer Page page Views =============//

import {
  ProfilePencipta,
  PermintaanPembayaran,
  AjukanPembayaran,
  DashboardPencipta,
  DetailLaguPencipta,
  DetailPublisher,
  KontrakListPencipta,
  KontrakListDetailPencipta,
} from "pages/Pencipta";

import AdminLandingPage from "../../pages/AdminLandingPage";

import {
  AdvancedPageFinance,
  AddAdvancedFinance,
  DetailAdvancedFinance,
  PrintPdfAdvancedPage,
} from "pages/Finance";

import PdfReader from "../../pages/LandingPage/PdfReader";
import ManajemenWeb from "../../pages/Publisher/ManajemenWeb";
import DetailDSP from "../../pages/Publisher/Master/PublisherDsp/detail";
import ReportView from "../../pages/Publisher/Report";
import DetailReportView from "../../pages/Publisher/Report/DetailReport";
import AuthLayout from "../../layouts/Auth";
import DashboardAdminLayout from "../../layouts/DashboardAdmin";
import ErrorLayout from "../../layouts/Error";
import NotificationPage from "../../pages/NotificationPage";
import DetailNotification from "../../pages/NotificationPage/Detail";

// =========== Landing page Views =============//

import {
  About,
  Contact,
  Blog,
  Partner,
  DetailBlog,
  DetailPartner,
} from "pages/MpisNewLandingPage";

import LoginAdminLanding from "../../pages/LandingPage/LoginAdmin/LoginAdmin";
import BeritaDetailView from "../../pages/LandingPage/Berita/DetailBerita";
import DetailLagu from "../../pages/LandingPage/DetailLagu/DetailLagu";
import LoginCaris from "../../pages/LandingPage/LoginCaris/LoginCaris";

import NewPassword from "../../pages/LandingPage/NewPassword/NewPassword";
import RedirectPage from "../../pages/RedirectPage";
import ChangePassword from "../../pages/changePassword";

import BeritaIklanPublisher from "../../pages/Publisher/BeritaIklanPublisher";
import DetailBerita from "../../pages/Publisher/BeritaIklanPublisher/DetailBerita";
import EditBerita from "../../pages/Publisher/BeritaIklanPublisher/EditBerita";
import PreviewBeritaEdit from "../../pages/Publisher/BeritaIklanPublisher/PreviewBeritaEdit";
import PreviewBeritaTambah from "../../pages/Publisher/BeritaIklanPublisher/PreviewBeritaTambah";
import TambahBerita from "../../pages/Publisher/BeritaIklanPublisher/TambahBerita";

import DashboardPublisher from "../../pages/Publisher/Dashboard";
import PrintReportPage from "../../pages/Publisher/Dashboard/PrintReportPage";

import {
  PersonalisasiPublisher,
  CostType,
  PembayaranPublisher,
  OriginalPublisherPublisher,
  AktifitasPublisher,
  TambahCostType,
  EditCostType,
  SubReporting,
  DetailSubReporting,
  SubReportingApproval,
} from "pages/Publisher/KonfigurasiPublisher";

import {
  KontrakPublisher,
  DetailKontrakPublisher,
  KontrakPublisherTambah,
} from "pages/Publisher";

import ProfilePublisher from "../../pages/Publisher/Profile";
import MasterPublisherPencipta from "../../pages/Publisher/Master/Pencipta";
import MasterPublisherPenciptaEdit from "../../pages/Publisher/Master/Pencipta/EditPencipta";
import MasterPublisherPenciptaTambah from "../../pages/Publisher/Master/Pencipta/TambahPencipta";
import MasterPublisherPerformer from "../../pages/Publisher/Master/Performer";
import MasterPublisherPerformerEdit from "../../pages/Publisher/Master/Performer/EditPerformer";
import MasterPublisherPerformerTambah from "../../pages/Publisher/Master/Performer/TambahPerformer";
import MasterPublisherUser from "../../pages/Publisher/Master/User";
import MasterPublisherUserEdit from "../../pages/Publisher/Master/User/EditUser";
import MasterPublisherUserTambah from "../../pages/Publisher/Master/User/TambahUser";
import MasterPublisherLagu from "../../pages/Publisher/Master/Lagu";
import MasterPublisherLaguEdit from "../../pages/Publisher/Master/Lagu/FormParameterSong";
import MasterPublisherLaguTambah from "../../pages/Publisher/Master/Lagu/FormParameterSong";
import MasterPublisherDsp from "../../pages/Publisher/Master/PublisherDsp";

import ReviewLagu from "../../pages/Publisher/ReviewLagu";
import DetailReviewLagu from "../../pages/Publisher/ReviewLagu/DetailReviewLagu";

import PreviewManajemenWeb from "../../pages/Publisher/ManajemenWeb/PreviewManajemenWeb";
import Otp from "../../pages/LandingPage/Otp/Otp";

import PrintReportDetailReview from "../../pages/Publisher/ReviewLagu/DetailReviewLagu/PrintReportDetailReview";
import PrintReportReview from "../../pages/Publisher/ReviewLagu/PrintReportReview";
import InvitationPassword from "../../pages/InvitationPassword/InvitationPassword";

import PrivacyPolicy from "../../pages/LandingPage/PrivacyPolicy";

import ClientClassification from "../../pages/Publisher/Master/ClientClassification";
import DoubleClaim from "../../pages/Publisher/SongClaim/DoubleClaim";
import SongUnclaimPublisher from "../../pages/Publisher/SongClaim/SongUnclaim";
import UnclaimMonitoring from "../../pages/Publisher/SongClaim/UnclaimMonitoring";
import DetailUnclaimMonitoring from "../../pages/Publisher/SongClaim/UnclaimMonitoring/DetailUnclaimMonitoring";
import ExchangesRate from "../../pages/Publisher/ExchangesRate";
import AddExRate from "../../pages/Publisher/ExchangesRate/AddExRate/AddExRate";
import ViewExRate from "../../pages/Publisher/ExchangesRate/ViewExRate/ViewExRate";
import UploadMonitoring from "../../pages/Publisher/UploadMonitoring";
import DetailMonitor from "../../pages/Publisher/UploadMonitoring/DetailMonitor";
import InitCataloguePage from "../../pages/InitCatalogue";
import DownloadMonitoring from "../../pages/Publisher/DownloadMonitoring";
import DownloadCCID from "../../pages/Publisher/DownloadCCID";

const routesUsed = [
  {
    path: "/",
    exact: true,
    component: LoginCaris,
  },
  {
    path: "/partner",
    exact: true,
    isAuth: false,
    component: Partner,
  },
  {
    path: "/partner/:id",
    exact: true,
    isAuth: false,
    component: DetailPartner,
  },
  {
    path: "/partner/:id",
    exact: true,
    isAuth: false,
    component: DetailPartner,
  },
  {
    path: "/about",
    exact: true,
    isAuth: false,
    component: About,
  },
  {
    path: "/blog",
    exact: true,
    isAuth: false,
    component: Blog,
  },
  {
    path: "/blog/:id",
    exact: true,
    isAuth: false,
    component: DetailBlog,
  },
  {
    path: "/contact",
    exact: true,
    isAuth: false,
    component: Contact,
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("../../views/Error401")),
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("../../views/Error404")),
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("../../views/Error500")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/redirect/:token",
    exact: true,
    component: RedirectPage,
  },
  {
    path: "/otp",
    exact: true,
    isAuth: false,
    component: Otp,
  },
  {
    path: "/login",
    exact: true,
    isAuth: false,
    component: LoginCaris,
  },
  {
    path: "/resource/info",
    exact: true,
    isAuth: false,
    component: PdfReader,
  },
  {
    path: "/admin-login",
    exact: true,
    isAuth: false,
    component: LoginAdminLanding,
  },
  {
    path: "/privacy-policy",
    exact: true,
    isAuth: false,
    component: PrivacyPolicy,
  },
  {
    path: "/new-password/:token",
    exact: true,
    component: NewPassword,
  },
  {
    path: "/invitation/password/:token",
    exact: true,
    isAuth: false,
    component: InvitationPassword,
  },
  {
    path: "/artikel/detail/:id",
    exact: true,
    isAuth: false,
    component: BeritaDetailView,
  },
  {
    path: "/detail-lagu",
    exact: true,
    isAuth: false,
    component: DetailLagu,
  },

  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("../../views/Login")),
      },
      {
        path: "/auth/register",
        exact: true,
        component: lazy(() => import("../../views/Register")),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: "/example",
    component: ExampleLayout,
    routes: [
      {
        path: "/example/documentation",
        exact: true,
        role: ["publisher"],
        component: () => {
          return <div>vvv</div>;
        },
      },
    ],
  },
  {
    path: "/admin",
    component: DashboardAdminLayout,
    routes: [
      {
        path: "/admin/dashboard-association",
        exact: true,
        role: ["publisher"],
        component: DashboardAssoc,
      },
      {
        path: "/admin/dashboard",
        exact: true,
        role: ["publisher", "society", "association"],
        component: DashboardPublisher,
      },
      {
        path: "/admin/dashboard/print-report",
        exact: true,
        component: PrintReportPage,
        role: ["publisher", "admin", "society", "association"],
      },
      {
        path: "/admin/billing/publisher",
        exact: true,
        component: Billing,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/billing/dsp",
        exact: true,
        component: BillingDsp,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/billing/detail/:id",
        exact: true,
        component: BillingDetail,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/profile",
        exact: true,
        component: ProfilePublisher,
        role: ["publisher", "admin", "society", "association"],
      },
      {
        path: "/admin/berita-publisher",
        exact: true,
        component: BeritaIklanPublisher,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/notifikasi",
        exact: true,
        component: NotificationPage,
      },
      {
        path: "/admin/notifikasi/:id",
        exact: true,
        component: DetailNotification,
      },
      {
        path: "/admin/berita-publisher/edit/preview",
        exact: true,
        component: PreviewBeritaEdit,
      },
      {
        path: "/admin/berita-publisher/edit/:id",
        exact: true,
        component: EditBerita,
      },
      {
        path: "/admin/berita-publisher/detail/:id",
        exact: true,
        component: DetailBerita,
      },
      {
        path: "/admin/berita-publisher/tambah",
        exact: true,
        component: TambahBerita,
      },
      {
        path: "/admin/berita-publisher/tambah/preview",
        exact: true,
        component: PreviewBeritaTambah,
      },
      {
        path: "/admin/advance",
        exact: true,
        component: AdvancedPageFinance,
      },
      {
        path: "/admin/advance/add",
        exact: true,
        component: AddAdvancedFinance,
      },
      {
        path: "/admin/advance/:id",
        exact: true,
        component: DetailAdvancedFinance,
      },
      {
        path: "/admin/advanced/print-as-pdf/:id",
        exact: true,
        component: PrintPdfAdvancedPage,
      },
      {
        path: "/admin/konfigurasi/exchange-rate",
        exact: true,
        component: ExchangesRate,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/konfigurasi/exchange-rate/add/:id/:currency",
        exact: true,
        component: AddExRate,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/konfigurasi/exchange-rate/view/:id/:currency",
        exact: true,
        component: ViewExRate,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/song-unclaim",
        exact: true,
        component: SongUnclaim,
      },
      {
        path: "/admin/publisher/double-claim",
        exact: true,
        component: DoubleClaim,
      },
      {
        path: "/admin/publisher/song-unclaim",
        exact: true,
        component: SongUnclaimPublisher,
      },
      {
        path: "/admin/publisher/unclaim-monitoring",
        exact: true,
        component: UnclaimMonitoring,
      },
      {
        path: "/admin/publisher/unclaim-monitoring/:id",
        exact: true,
        component: DetailUnclaimMonitoring,
      },
      {
        path: "/admin/parameter/pencipta",
        exact: true,
        component: MasterPublisherPencipta,
      },
      {
        path: "/admin/parameter/pencipta/tambah",
        exact: true,
        component: MasterPublisherPenciptaTambah,
      },
      {
        path: "/admin/parameter/pencipta/edit/:id",
        exact: true,
        component: MasterPublisherPenciptaEdit,
      },
      {
        path: "/admin/parameter/client-classification",
        exact: true,
        component: ClientClassification,
      },
      {
        path: "/admin/parameter/lagu",
        exact: true,
        component: MasterPublisherLagu,
      },
      {
        path: "/admin/parameter/lagu/tambah",
        exact: true,
        component: MasterPublisherLaguTambah,
      },
      {
        path: "/admin/parameter/lagu/edit/:id",
        exact: true,
        component: MasterPublisherLaguEdit,
      },
      {
        path: "/admin/parameter/dsp",
        exact: true,
        component: MasterPublisherDsp,
      },
      {
        path: "/admin/parameter/dsp/detail/:id",
        exact: true,
        component: DetailDSP,
      },
      {
        path: "/admin/parameter/performer",
        exact: true,
        component: MasterPublisherPerformer,
      },
      {
        path: "/admin/parameter/performer/tambah",
        exact: true,
        component: MasterPublisherPerformerTambah,
      },
      {
        path: "/admin/parameter/performer/ubah/:id",
        exact: true,
        component: MasterPublisherPerformerEdit,
      },
      {
        path: "/admin/parameter/user",
        exact: true,
        component: MasterPublisherUser,
      },
      {
        path: "/admin/parameter/user/tambah",
        exact: true,
        component: MasterPublisherUserTambah,
      },
      {
        path: "/admin/parameter/user/edit/:id",
        exact: true,
        component: MasterPublisherUserEdit,
      },
      {
        path: "/admin/konfigurasi/personalisasi",
        exact: true,
        component: PersonalisasiPublisher,
      },
      {
        path: "/admin/konfigurasi/cost-type",
        exact: true,
        component: CostType,
      },
      {
        path: "/admin/konfigurasi/add-cost-type",
        exact: true,
        component: TambahCostType,
      },
      {
        path: "/admin/konfigurasi/edit-cost-type/:id",
        exact: true,
        component: EditCostType,
      },
      {
        path: "/admin/konfigurasi/pembayaran",
        exact: true,
        component: PembayaranPublisher,
      },
      {
        path: "/admin/konfigurasi/original-publisher",
        exact: true,
        component: OriginalPublisherPublisher,
      },
      {
        path: "/admin/konfigurasi/aktifitas",
        exact: true,
        component: AktifitasPublisher,
      },
      {
        path: "/admin/konfigurasi/sub-reporting",
        exact: true,
        component: SubReporting,
      },
      {
        path: "/admin/konfigurasi/sub-reporting/:id",
        exact: true,
        component: DetailSubReporting,
      },
      {
        path: "/admin/konfigurasi/sub-reporting-approval",
        exact: true,
        component: SubReportingApproval,
      },
      {
        path: "/admin/kontrak",
        exact: true,
        component: KontrakPublisher,
      },
      {
        path: "/admin/kontrak/detail/:id",
        exact: true,
        component: DetailKontrakPublisher,
      },
      {
        path: "/admin/kontrak/tambah",
        exact: true,
        component: KontrakPublisherTambah,
      },
      {
        path: "/admin/review-lagu-association",
        exact: true,
        component: ReviewLaguAssoc,
      },
      {
        path: "/admin/reports",
        exact: true,
        component: ReportView,
      },
      {
        path: "/admin/reports/:id",
        exact: true,
        component: DetailReportView,
      },
      {
        path: "/admin/review-lagu-association/print-report",
        exact: true,
        component: PrintReportReviewAssoc,
      },
      {
        path: "/admin/review-lagu-association/:id",
        exact: true,
        component: DetailReviewLaguAssoc,
      },
      {
        path: "/admin/review-lagu-association/detail/print-report",
        exact: true,
        component: PrintReportDetailReviewAssoc,
      },
      {
        path: "/admin/review-lagu",
        exact: true,
        role: ["publisher", "admin", "society", "association"],
        component: ReviewLagu,
      },
      {
        path: "/admin/review-lagu/print-report",
        exact: true,
        component: PrintReportReview,
        role: ["publisher", "admin", "society", "association"],
      },
      {
        path: "/admin/review-lagu/:id",
        exact: true,
        role: ["publisher", "admin", "society", "association"],
        component: DetailReviewLagu,
      },
      {
        path: "/admin/review-lagu/detail/print-report",
        exact: true,
        role: ["publisher", "admin", "society", "association"],
        component: PrintReportDetailReview,
      },
      {
        path: "/admin/change-password",
        exact: true,
        component: ChangePassword,
      },
      {
        path: "/admin/konfigurasi/sistem",
        exact: true,
        component: props => {
          return <KonfigurasiAdminSistem {...props} mustLoggedIn={true} />;
        },
      },
      {
        path: "/admin/konfigurasi/template-notifikasi",
        exact: true,
        component: KonfigurasiAdminTemplateNotifikasi,
      },
      {
        path: "/admin/konfigurasi/message",
        exact: true,
        component: KonfigurasiAdminMessage,
      },
      {
        path: "/admin/konfigurasi/message/add",
        exact: true,
        component: KonfigurasiAdminMessageAdd,
      },
      {
        path: "/admin/konfigurasi/message/edit/:id",
        exact: true,
        component: KonfigurasiAdminMessageEdit,
      },
      {
        path: "/admin/konfigurasi/pajak",
        exact: true,
        component: KonfigurasiAdminPajak,
      },
      {
        path: "/admin/konfigurasi/pajak/add",
        exact: true,
        component: FormTax,
      },
      {
        path: "/admin/konfigurasi/pajak/edit/:id",
        exact: true,
        component: FormTax,
      },
      {
        path: "/admin/konfigurasi/role-user",
        exact: true,
        component: KonfigurasiAdminRoleUser,
      },
      {
        path: "/admin/konfigurasi/role-user/:type/:role_type",
        exact: true,
        component: FormRole,
      },
      {
        path:
          "/admin/konfigurasi/role-user/:type/:role_type/:role_id/:selected_app",
        exact: true,
        component: FormRole,
      },
      {
        path: "/admin/konfigurasi/aktifitas-admin",
        exact: true,
        component: KonfigurasiAdminAktifitas,
      },
      {
        path: "/admin/konfigurasi/dsp-type-revenue",
        exact: true,
        component: KonfigurasiAdminDspTypeRevenue,
      },
      {
        path: "/admin/konfigurasi/dsp-type-revenue/add",
        exact: true,
        component: KonfigurasiAdminDspTypeRevenueAdd,
      },
      {
        path: "/admin/konfigurasi/dsp-type-revenue/edit/:id",
        exact: true,
        component: KonfigurasiAdminDspTypeRevenueEdit,
      },
      {
        path: "/admin/konfigurasi/billing-configuration",
        exact: true,
        component: BillingKonfigurasi,
      },
      {
        path: "/admin/konfigurasi/announcement-management",
        exact: true,
        component: KonfigurasiAdminAnnouncementManagement,
      },
      {
        path:
          "/admin/konfigurasi/announcement-management/add/:typeAnnouncement",
        exact: true,
        component: KonfigurasiAdminAnnouncementManagementAddOrEdit,
      },
      {
        path: "/admin/konfigurasi/announcement-management/edit/:id",
        exact: true,
        component: KonfigurasiAdminAnnouncementManagementAddOrEdit,
      },
      {
        path: "/admin/konfigurasi/announcement-management/view/:id",
        exact: true,
        component: KonfigurasiAdminAnnouncementManagementDetail,
      },
      {
        path: "/admin/parameter/publisher",
        exact: true,
        component: MasterAdminPublisher,
      },
      {
        path: "/admin/parameter/publisher/add",
        exact: true,
        component: AddPublisher,
      },
      {
        path: "/admin/parameter/publisher/:id",
        exact: true,
        component: EditPublisher,
      },
      {
        path: "/admin/parameter/association",
        exact: true,
        component: MasterAssociation,
      },
      {
        path: "/admin/parameter/association/add",
        exact: true,
        component: AddAsosiasi,
      },
      {
        path: "/admin/parameter/association/:id",
        exact: true,
        component: EditAsosiasi,
      },
      {
        path: "/admin/parameter/admin-currency",
        exact: true,
        component: MasterAdminCurrency,
      },
      {
        path: "/admin/parameter/admin-currency/tambah",
        exact: true,
        component: MasterAdminCurrencyTambah,
      },
      {
        path: "/admin/parameter/admin-currency/edit/:id",
        exact: true,
        component: MasterAdminCurrencyEdit,
      },
      {
        path: "/admin/parameter/admin-revenue-type",
        exact: true,
        component: MasterRevenueType,
      },
      {
        path: "/admin/parameter/admin-revenue-type/tambah",
        exact: true,
        component: MasterRevenueTypeCreateOrEdit,
      },
      {
        path: "/admin/parameter/admin-revenue-type/edit/:id",
        exact: true,
        component: MasterRevenueTypeCreateOrEdit,
      },
      {
        path: "/admin/parameter/admin-country",
        exact: true,
        component: MasterCountry,
      },
      {
        path: "/admin/parameter/admin-country/tambah",
        exact: true,
        component: MasterCountryCreateOrEdit,
      },
      {
        path: "/admin/parameter/admin-country/edit/:id",
        exact: true,
        component: MasterCountryCreateOrEdit,
      },
      {
        path: "/admin/parameter/lagu-admin",
        exact: true,
        component: MasterAdminLagu,
      },
      {
        path: "/admin/parameter/lagu-admin/tambah",
        exact: true,
        component: MasterAdminLaguTambah,
      },
      {
        path: "/admin/parameter/lagu-admin/edit/:id",
        exact: true,
        component: MasterAdminLaguEdit,
      },
      {
        path: "/admin/parameter/user-admin",
        exact: true,
        component: MasterAdminUser,
      },
      {
        path: "/admin/parameter/user-admin/tambah",
        exact: true,
        component: MasterAdminTambahUser,
      },
      {
        path: "/admin/parameter/user-admin/edit/:id",
        exact: true,
        component: MasterAdminEditUser,
      },
      {
        path: "/admin/parameter/bank-admin",
        exact: true,
        component: MasterAdminBank,
      },
      {
        path: "/admin/parameter/bank-admin/tambah",
        exact: true,
        component: MasterAdminBankTambah,
      },
      {
        path: "/admin/parameter/bank-admin/ubah/:id",
        exact: true,
        component: MasterAdminBankUbah,
      },
      {
        path: "/admin/parameter/pencipta-admin/:id",
        exact: true,
        component: MasterAdminPenciptaEdit,
      },
      {
        path: "/admin/parameter/pencipta-admin",
        exact: true,
        component: MasterAdminPencipta,
      },
      {
        path: "/admin/parameter/dsp-admin",
        exact: true,
        component: MasterAdminDsp,
      },
      {
        path: "/admin/parameter/dsp-admin/add",
        exact: true,
        component: TambahDsp,
      },
      {
        path: "/admin/parameter/dsp-admin/:id",
        exact: true,
        component: EditDsp,
      },
      {
        path: "/admin/parameter/user-publisher",
        exact: true,
        component: MasterAdminUserPublisher,
      },
      {
        path: "/admin/parameter/user-pencipta",
        exact: true,
        component: MasterAdminUserPencipta,
      },
      {
        path: "/admin/parameter/territory",
        exact: true,
        component: MasterAdminTerritory,
      },
      {
        path: "/admin/parameter/territory/:type",
        exact: true,
        component: MasterAdminAddEditTerritory,
      },
      {
        path: "/admin/parameter/territory/:type/:id",
        exact: true,
        component: MasterAdminAddEditTerritory,
      },
      {
        path: "/admin/parameter/society",
        exact: true,
        component: MasterAdminSociety,
      },
      {
        path: "/admin/parameter/society/add",
        exact: true,
        component: MasterAdminEditOrAddSociety,
      },
      {
        path: "/admin/parameter/society/edit/:id",
        exact: true,
        component: MasterAdminEditOrAddSociety,
      },
      {
        path: "/admin/parameter/user-society",
        exact: true,
        component: MasterAdminUserSociety,
      },
      {
        path: "/admin/berita",
        exact: true,
        component: BeritaIklanAdmin,
      },
      {
        path: "/admin/berita/tambah",
        exact: true,
        component: TambahBerita,
      },
      {
        path: "/admin/berita/tambah/preview",
        exact: true,
        component: PreviewBeritaTambah,
      },
      {
        path: "/admin/berita/edit/preview",
        exact: true,
        component: PreviewBeritaEdit,
      },
      {
        path: "/admin/berita/:id",
        exact: true,
        component: EditBerita,
      },
      {
        path: "/admin/finalisasi-berita",
        exact: true,
        component: FinalisasiBeritaIklanAdmin,
      },
      {
        path: "/admin/finalisasi-berita/view/preview",
        exact: true,
        component: PreviewFinalisasiBerita,
      },
      {
        path: "/admin/finalisasi-berita/view/:id",
        exact: true,
        component: PreviewBeritaIklanAdmin,
      },
      {
        path: "/admin/manajemen-web",
        exact: true,
        component: ManajemenWeb,
      },
      {
        path: "/admin/manajemen-web/preview",
        exact: true,
        component: PreviewManajemenWeb,
      },
      {
        path: "/admin/manajemen-web-admin",
        exact: true,
        component: ManajemenWebAdmin,
      },
      {
        path: "/admin/manajemen-web/preview-admin",
        exact: true,
        component: PreviewManajemenWebAdmin,
      },
      {
        path: "/admin/landing-page",
        exact: true,
        component: AdminLandingPage,
      },
      {
        path: "/admin/upload-monitoring",
        exact: true,
        component: UploadMonitoring,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/detail-upload-monitoring/:id",
        exact: true,
        component: DetailMonitor,
        role: ["publisher", "admin"],
      },
      {
        path: "/admin/init-catalogue",
        exact: true,
        component: InitCataloguePage,
      },
      {
        path: "/admin/download-monitoring",
        exact: true,
        component: DownloadMonitoring,
        role: ["publisher"],
      },
      {
        path: "/admin/download-ccid",
        exact: true,
        component: DownloadCCID,
      },
      {
        path: "/admin/usage-process",
        exact: true,
        component: UsageProcess,
      },
    ],
  },
  {
    path: "/finance",
    component: DashboardAdminLayout,
    routes: [
      {
        path: "/finance/advance",
        exact: true,
        component: AdvancedPageFinance,
      },
      {
        path: "/finance/advance/add",
        exact: true,
        component: AddAdvancedFinance,
      },
      {
        path: "/finance/advanced/print-as-pdf/:id",
        exact: true,
        component: PrintPdfAdvancedPage,
      },
      {
        path: "/finance/advance/:id",
        exact: true,
        component: DetailAdvancedFinance,
      },
    ],
  },
  {
    path: "/pencipta",
    component: DashboardAdminLayout,
    routes: [
      {
        path: "/pencipta/dashboard-pencipta",
        exact: true,
        component: () => <DashboardPencipta roles={["composer"]} />,
      },
      {
        path: "/pencipta/dashboard-pencipta/detail-lagu/:id",
        exact: true,
        role: ["composer"],
        component: DetailLaguPencipta,
      },
      {
        path: "/pencipta/dashboard-pencipta/detail-lagu/detail-publisher/:id",
        exact: true,
        component: DetailPublisher,
      },
      {
        path: "/pencipta/kontrak",
        exact: true,
        role: ["pencipta"],
        component: KontrakListPencipta,
      },
      {
        path: "/pencipta/kontrak/detail-kontrak/:id",
        exact: true,
        component: KontrakListDetailPencipta,
      },
      {
        path: "/pencipta/permintaan-pembayaran",
        exact: true,
        component: PermintaanPembayaran,
      },
      {
        path: "/pencipta/permintaan-pembayaran/ajukan-pembayaran",
        exact: true,
        component: AjukanPembayaran,
      },
      {
        path: "/pencipta/profile-pencipta",
        exact: true,
        component: ProfilePencipta,
        role: ["publisher", "admin", "composer"],
      },
    ],
  },
  {
    component: () => <Redirect to="/errors/error-404" />,
  },
];
const itemRoutes = routesUsed;
export default itemRoutes;
