import { useEffect } from "react";
import {
  CircularProgress,
  Container,
  Drawer,
  Hidden,
  Typography,
  Grid,
  Box,
  Button,
  ListItem,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { InnoImage } from "inno-ui";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router";
import CARIS from "../../assets/img/newCarisLogo.webp";
import MPIS from "../../assets/img/newMpisLogo.webp";
import { hardTypeWeb, hardSubDomain } from "../../services/urlConstant";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const getColor = () => {
  let theColor = localStorage.getItem("themeColor") || "#9e42f5";
  if (hardSubDomain) {
    theColor = "#181616";
  } else if (hardTypeWeb === "caris") {
    theColor = "#111827";
  }
  return theColor;
};

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F9FAFB",
  },
  item: {
    display: "block",
    padding: 0,
  },
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonSelected: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    color: "white",
    width: "100%",
    backgroundColor: "black"
  },
  buttonChildren: {
    padding: "10px 2.5rem",
    justifyContent: "flex-start",
    textTransform: "none",
    fontWeight: 200,
    textAlign: "left",
    letterSpacing: 0,
    width: "100%",
    color: "grey",
    cursor: "pointer"
  },
  buttonChildrenSelected: {
    padding: "10px 2.5rem",
    justifyContent: "flex-start",
    textTransform: "none",
    fontWeight: 200,
    textAlign: "left",
    color: "black",
    letterSpacing: 0,
    width: "100%",
    cursor: "pointer"
  },
  expandIcon: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },
  active: {
    color: "white",
    backgroundColor: getColor(),
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      color: "white",
      backgroundColor: getColor(),
    },
  },
  rootCircular: {
    margin: "auto",
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    zIndex: 999,
    width: 256,
    height: "100%",
  },
  navigation: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "auto",
    flexGrow: 1,
  },
  profileContainer: {
    minHeight: "64px",
    display: "flex",
    alignItems: "center"
  },
  profileText: {
    fontSize: "12px",
    color: "#687083",
  },
  profileImage: {
    display: "block",
    marginLeft: "25px",
  },
}));

function NavBar({
  loadingComponent,
  selectedNavItems,
  openMobile,
  onMobileClose,
  className,
  theColor,
  ...rest
}) {
  const classes = useStyles({ theColor });
  const location = useLocation();
  const history = useHistory();

  const MpisBottomBar = () => {
    const isMPIS = hardTypeWeb === "mpis";
    const logo = isMPIS ? MPIS : CARIS;
    const logoStyle = isMPIS
      ? { width: "80px", height: "80px" }
      : { width: "99.73px", height: "30px", marginTop: "20px" };

    return (
      <Box className={classes.profile}>
        <Container className={classes.profileContainer}>
          <ThemeProvider theme={theme}>
            <Typography className={classes.profileText}>
              Powered by
            </Typography>
          </ThemeProvider>
          <InnoImage
            alt="Logo"
            src={logo}
            styleImageContainer={{
              boxShadow: "none",
              padding: 0,
              backgroundColor: "transparent",
            }}
            styleImage={logoStyle}
            fill={true}
          />
        </Container>
      </Box>
    );
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  console.log({ selectedNavItems })
  const Content = () => (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.navigation}>
        {selectedNavItems?.map(({ title, children, href, icon, selectedIcon }, key) => {
          const filterTitle = title === "Master" ? "Parameter" : title;
          const isSelected = location.pathname === href;
          const isChildrenSelected = children?.find((item) => item?.href === location.pathname);
          return (
            <ListItem key={key} className={clsx(classes.item, className)}>
              <Button
                className={(isSelected || isChildrenSelected) ? classes.buttonSelected : classes.button}
                onClick={() => {
                  if (children.length > 0 && children[0]?.href) {
                    history.push(children[0]?.href);
                  } else {
                    history.push(href);
                  }
                }}
              >
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <Box>{(isSelected || isChildrenSelected) ? selectedIcon : icon}</Box>
                      {filterTitle}
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </ListItem>
          );
        })}
      </Box>
      <MpisBottomBar />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Content />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {loadingComponent ? (
            <Box className={classes.rootCircular}>
              <CircularProgress disableShrink />
            </Box>
          ) : (
            <Content />
          )}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  loadingComponent: PropTypes.bool,
  selectedNavItems: PropTypes.array,
  openMobile: PropTypes.bool,
  onMobileClose: PropTypes.func,
  className: PropTypes.string,
};

export default NavBar;
