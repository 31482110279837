import axios from "axios";
import { ModalError } from "components";
import { isEqual } from "lodash";
import { createAllFilterOption, getErrors } from "utils";
import { hardBaseUrl } from "../services/urlConstant";

export const fetchAndFilterOptions = async ({
  endpoint,
  headers,
  params,
  label,
  key,
  setLoading,
  paramsCondition,
  prevPagination,
  setOptions,
  selectedOptions,
  setSelectedOptions,
}) => {
  try {
    if (typeof setLoading === "function") {
      setLoading(true);
    }
    const res = await axios.get(`${hardBaseUrl}${endpoint}`, {
      headers,
      params,
    });
    const { data } = res?.data;
    const updatedOptionsList = paramsCondition
      ? data
      : [createAllFilterOption({ label, key }), ...data];
    setOptions(updatedOptionsList);

    if (!isEqual(prevPagination, paramsCondition)) {
      if (!selectedOptions.some(item => item?.[key] === "all")) {
        const filteredOptions = selectedOptions.filter(
          item => item[key] !== "all"
        );
        setSelectedOptions(filteredOptions);
      }
    }
  } catch (error) {
    ModalError(getErrors(error?.response));
  } finally {
    if (typeof setLoading === "function") {
      setLoading(false);
    }
  }
};

export default {
  fetchAndFilterOptions,
};
