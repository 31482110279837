import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Grid,
  Divider,
  TextField,
  InputAdornment,
  Card,
  Box,
  CardContent,
} from "@material-ui/core";
import { Page, SkeletonComponent, PrimaryButton } from "components";
import { ButtonGroupTop } from "../../../../components/atoms/Button";
import { PhotoshopPicker } from "react-color";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import { hardBaseUrl } from "../../../../services/urlConstant";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import { HeaderTitle } from "layouts";

function Personalisasi() {
  const theToken = localStorage.getItem("token");
  const uploadInputRef = useRef(null);
  const history = useHistory();
  const [loadingPageSkeleton, setLoadingPageSkeleton] = useState(false);
  const [color, setColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [dataError, setDataError] = useState({});
  const [dataProfile, setDataProfile] = useState({
    name: "",
    publisher_id: "",
    email: "",
    phone: "",
    subdomain: "",
    address: "",
    profile_image: "",
  });
  const [dataImage, setDataImage] = useState({
    image_url: null,
    image: null,
  });
  const [getColor, setGetColor] = useState("");
  const theme = createTheme({
    typography: {
      fontFamily: ["Inter"].join(","),
    },
  });
  const getMainDsp = async () => {
    setLoadingPageSkeleton(true);
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/me`;
      const res = await Axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setGetColor(res.data.data.publisher.theme_color);
      setLoadingPageSkeleton(false);
    } catch (e) {
      setLoadingPageSkeleton(false);
      new Error(e);
    }
  };
  useEffect(() => {
    getMainDsp();
    getDataProfile();
  }, []);
  const getDataProfile = async () => {
    let response = await Axios.get(hardBaseUrl + "/publisher/profile", {
      headers: {
        Authorization: "Bearer " + theToken,
      },
    });
    setDataProfile(response.data.data);
    setColor(response.data.data.theme_color);
  };
  const colorPreview = makeStyles(() => ({
    changeColor: {
      backgroundColor: color,
      padding: "7px",
      marginRight: "10px",
      marginLeft: "10px",
    },
  }));
  const colorPrev = colorPreview();
  const handleProfil = async () => {
    let profileData = {
      name: "",
      email: "",
      phone: "",
      subdomain: "",
      address: "",
    };
    let validData = Object.entries(profileData);
    let inputError = {};
    validData.forEach(res => {
      if (dataProfile[res[0]] === "") {
        Object.assign(inputError, { [res[0]]: true });
      }
    });
    setDataError(inputError);
    const inputValid = Object.entries(inputError);
    if (inputValid.length === 0) {
      const dataBody = {
        name: dataProfile.name,
        email: dataProfile.email,
        phone: dataProfile.phone,
        subdomain: dataProfile.subdomain,
        address: dataProfile.address,
        theme_color: color
      };
      const dataForm = new FormData();
      Object.keys(dataBody).forEach(key => dataForm.append(key, dataBody[key]));
      try {
        const response = await Axios.put(
          hardBaseUrl + "/publisher/profile",
          dataForm,
          {
            headers: {
              Authorization: "Bearer " + theToken,
            },
          }
        );
        if (response.data.message === "success") {
          Swal.fire({
            icon: "success",
            title: "Berhasil mengupdate data profile publisher",
            showConfirmButton: false,
            timer: 1500,
          });
          getDataProfile();
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: `${error.response.data.errors[0].message}`,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };
  const handleChangeInput = e => {
    setDataProfile({ ...dataProfile, [e.target.id]: e.target.value });
    let dataClone = { ...dataError };
    delete dataClone[e.target.id];
    setDataError(dataClone);
  };
  const handleUbahLogo = async () => {
    const dataForm = new FormData();
    dataForm.append("image_logo", dataImage.image);
    dataForm.append("theme_color", color);
    try {
      const response = await Axios.put(
        hardBaseUrl + "/publisher/profile-image",
        dataForm,
        {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        }
      );
      if (response.data.message === "success") {
        setDataImage({ image: null, image_url: null });
        localStorage.setItem("themeColor", color);
        Swal.fire({
          icon: "success",
          title: "Berhasil mengupdate image profile publisher",
          confirmButtonText: "Ok",
        }).then(result => {
          if (result.isConfirmed) {
            history.go(0);
          }
        });
      }
    } catch (error) {
      new Error(error);
    }
  };
  const handleChangeImage = e => {
    setDataImage({
      image_url: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      "& > *": {
        fontFamily: ["\"Inter\""].join(","),
        textTransform: "none",
      },
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    results: {
      marginTop: theme.spacing(3),
    },
    formField: {
      width: "90%",
      marginTop: "0px",
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
      },
    },
    singleFormField: {
      marginTop: "0px",
      width: "95%",
      "& .MuiOutlinedInput-root": {
        paddingRight: 0,
      },
    },
    singleFormFieldSubDomain: {
      width: "95%",
      "& .MuiOutlinedInput-root": {
        paddingRight: 0,
      },
    },
    profilePublisherArea: {
      marginTop: "50px",
    },
    tampilanArea: {
      marginTop: "50px",
      marginBottom: "50px",
    },
    domainDivider: {
      height: 30,
      marginRight: "10px",
    },
    colorDivider: {
      height: 30,
    },
    textStyle: {
      wordWrap: "break-word",
      color: "gray",
      fontSize: "14px",
    },
    dropzoneArea: {
      "&:hover": {},
      marginTop: "5px",
      marginBottom: "20px",
      width: "90%",
      height: "120px",
      borderRadius: "10px",
      backgroundColor: "#F9FAFB",
      border: "1px solid #D1D5DC",
      position: "relative",
    },
    colorPickerArea: {
      marginTop: "5px",
    },
    upload_image: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "gray",
      top: "0px",
      borderRadius: "10px",
      opacity: "0.5",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    colorPicker: {
      width: "400px !important",
      "& .photoshop-picker": {
        width: "200px !important"
      },
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "700",
      width: "537px",
      height: "28px",
      lineHeight: "28px",
      fontFamily: ["\"Inter\""].join(","),
    },
    button: props => ({
      padding: "10px 28px 10px 28px",
      backgroundColor: props.backgroundColor || "black",
      color: "white",
      width: "182px",
      height: "40px",
      borderRadius: "6px",
      marginTop: "20px",
      textTransform: "none",
    }),
    textButton: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "500",
      fontStyle: "normal",
      textTransform: "none",
      fontFamily: ["\"Inter\""].join(","),
      whiteSpace: "nowrap",
    },
    label: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: "#364052",
      fontFamily: ["\"Inter\""].join(","),
    },
    labelRed: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: dataError.name && "red",
      fontFamily: ["\"Inter\""].join(","),
    },
    adornedEnd: {
      background: "linear-gradient(-90deg, #F9FAFB 30%, #FFF 30%)",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    font24: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "32px",
    },
    cardParent: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
      boxShadow: "none",
    },

    adorment: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      height: 55,
      width: 110,
      marginLeft: 10,
      border: "0px solid green",
      marginRight: 5,
      paddingRight: "20px",
      color: "#9AA2B1",
    },

    adorment2: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: "#F9FAFB",
      height: 40,
      width: 50,
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    images: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      padding: "5px",
    },
  }));

  const classes = useStyles({ backgroundColor: getColor });

  return (
    <Page className={classes?.root} title="Personalization">
      {loadingPageSkeleton ? (
        <SkeletonComponent variant="wave" />
      ) : (
        <Container maxWidth={false}>
          <HeaderTitle title="Configuration" breadcrumbData={breadcrumbData} />
          <ButtonGroupTop />
          <Divider className={classes?.divider} />
          <Box className={classes?.profilePublisherArea}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes?.subtitle}>
                    Publisher Profile
                  </Typography>
                  <p className={classes?.textStyle}>
                    Customize publisher profile and URL to share
                  </p>
                </ThemeProvider>
                <Card className={classes?.cardParent}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6} md={6}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label
                              htmlFor="publisher-name"
                              className={
                                dataError?.name
                                  ? classes?.labelRed
                                  : classes?.label
                              }
                            >
                              Publisher Name *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            value={dataProfile.name}
                            onChange={handleChangeInput}
                            id="name"
                            name="publisher-name"
                            error={dataError?.name}
                            className={classes?.formField}
                            margin="normal"
                            type="text"
                            variant="outlined"
                            helperText={
                              dataError?.name &&
                              "Nama Publisher tidak boleh kosong"
                            }
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label
                              htmlFor="publisher-id"
                              className={classes?.label}
                            >
                              Publisher ID *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            disabled
                            value={dataProfile && dataProfile.publisher_id}
                            id="publisher-id"
                            name="publisher-id"
                            className={classes?.formField}
                            margin="normal"
                            type="text"
                            variant="outlined"
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label
                              htmlFor="email"
                              className={
                                dataError?.email
                                  ? classes?.labelRed
                                  : classes?.label
                              }
                            >
                              Email *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            value={dataProfile.email}
                            onChange={handleChangeInput}
                            error={dataError?.email}
                            id="email"
                            name="email"
                            className={classes?.formField}
                            margin="normal"
                            type="text"
                            variant="outlined"
                            helperText={
                              dataError?.email && "Email tidak boleh kosong"
                            }
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label
                              htmlFor="phone"
                              className={
                                dataError?.phone
                                  ? classes?.labelRed
                                  : classes?.label
                              }
                            >
                              Phone Number *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            value={dataProfile.phone}
                            onChange={handleChangeInput}
                            error={dataError?.phone}
                            id="phone"
                            name="phone"
                            className={classes?.formField}
                            margin="normal"
                            type="text"
                            variant="outlined"
                            helperText={
                              dataError?.phone &&
                              "Nomor Telepon tidak boleh kosong"
                            }
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label
                              htmlFor="sub-domain"
                              className={
                                dataError?.subdomain
                                  ? classes?.labelRed
                                  : classes?.label
                              }
                            >
                              Sub-Domain *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            onChange={handleChangeInput}
                            value={dataProfile.subdomain}
                            fullWidth
                            id="subdomain"
                            name="sub-domain"
                            error={dataError?.subdomain}
                            helperText={
                              dataError?.subdomain &&
                              "subdomain tidak boleh kosong"
                            }
                            className={classes?.singleFormField}
                            margin="normal"
                            type="text"
                            variant="outlined"
                            InputProps={{
                              inputProps: {
                                style: { textAlign: "right", paddingRight: 0 },
                              },
                              classes: {
                                adornedEnd: classes?.adornedEnd,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <div className={classes?.adorment}>
                                    .caris.id
                                  </div>
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label htmlFor="address" className={classes?.label}>
                              Address *
                            </label>
                          </Typography>
                          <TextField
                            size="small"
                            required
                            onChange={handleChangeInput}
                            value={dataProfile.address}
                            id="address"
                            name="address"
                            className={classes?.singleFormField}
                            multiline={true}
                            rows={4}
                            margin="normal"
                            type="text"
                            variant="outlined"
                          />
                        </ThemeProvider>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h5" className={classes?.subtitle}>
                    Appearance
                  </Typography>
                  <p className={classes?.textStyle}>
                    Choose your company logo and customize the color theme
                  </p>
                </ThemeProvider>
                <Card className={classes?.cardParent}>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6} md={6} lg={8} xl={8}>
                        <ThemeProvider theme={theme}>
                          <Typography>
                            <label htmlFor="logo" className={classes?.label}>
                              Logo Now
                            </label>
                          </Typography>
                          <div className={classes?.dropzoneArea}>
                            <img
                              alt="theImgs"
                              src={
                                dataImage.image_url || dataProfile.profile_image
                              }
                              className={classes?.images}
                            />
                            <div
                              className={classes?.upload_image}
                              onClick={() =>
                                uploadInputRef.current &&
                                uploadInputRef.current.click()
                              }
                            >
                              <input
                                ref={uploadInputRef}
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleChangeImage}
                              />
                              {dataImage.image_url === null ||
                                dataProfile.profile_image === "" ? (
                                ""
                              ) : (
                                <h2>Change Logo</h2>
                              )}
                            </div>
                          </div>
                        </ThemeProvider>
                      </Grid>
                      <Grid item xs={6} md={6} lg={4} xl={4}>
                        <PrimaryButton
                          label="Change Logo"
                          onClick={handleUbahLogo}
                        />
                      </Grid>
                      <Box>
                        <Typography>
                          <label htmlFor="theme" className={classes?.label}>
                            Theme Color
                          </label>
                        </Typography>
                        <div className={classes?.colorPickerArea}>
                          <TextField
                            className={classes?.formField}
                            value={color}
                            variant="outlined"
                            id="theme"
                            disabled
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div className={classes?.adorment2}>
                                    <div
                                      className={colorPrev.changeColor}
                                    ></div>
                                  </div>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ExpandMore />
                                </InputAdornment>
                              ),
                            }}
                            onClick={() =>
                              setShowColorPicker(
                                showColorPicker => !showColorPicker
                              )
                            }
                          />
                        </div>
                        {showColorPicker && (
                          <PhotoshopPicker
                            className={classes.colorPicker}
                            color={color}
                            onAccept={() => setShowColorPicker(showColorPicker => !showColorPicker)}
                            onCancel={() => setShowColorPicker(showColorPicker => !showColorPicker)}
                            onChange={updateColor => setColor(updateColor.hex)}
                          />
                        )}
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Box width="100%">
                <Divider className={classes?.divider} />
                <Box display="flex" justifyContent="flex-end" padding="8px">
                  <PrimaryButton label="Apply Changes" onClick={handleProfil} />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Container>
      )}
    </Page>
  );
}
const breadcrumbData = [
  {
    label: "Home",
    link: "/admin/dashboard",
  },
  {
    label: "Configuration",
    link: "/admin/konfigurasi/personalisasi",
  },
  {
    label: "Personalization",
    active: true,
  },
];
export default Personalisasi;
