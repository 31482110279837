import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
    borderTop: "1px solid #e1e1e1",
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  },
  pageSize: {
    height: "30px",
    borderRadius: "6px",
    border: "1px solid #D1D5DC",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "5px",
  },
  paginationText: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#687083",
  },
  breadcrumbs: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#111827",
  },
  breadCrumbsActive: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "regular",
    color: "#687083",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));

const PaymentPencipta = () => {
  const classes = useStyles();

  const pageSize = 10;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const currentPageApi = 1;
  const totalSizeApi = 10;

  const [response, setResponse] = useState([]);
  const [balance, setBalance] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoadingTable(true);
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const url =
          baseOfUrl +
          "/composer/transaction/payment?page=" +
          page +
          "&per_page=" +
          rowsPerPage;
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);

        setResponse(response.data.data.payment_data);
        setPage(response.data.data.query.page);
        setTotalData(response.data.data.query.total_page);
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
      }
    };
    const getBalance = async () => {
      try {
        let theToken = localStorage.getItem("token");
        let baseOfUrl = hardBaseUrl;
        const url = baseOfUrl + "/composer/transaction/payment/get-balance";
        const options = {
          headers: {
            Authorization: "Bearer " + theToken,
          },
        };
        const response = await axios.get(url, options);
        setBalance(response.data.data.balance_number);
      } catch (error) {
        new Error(error);
      }
    };
    getBalance();
    fetchUsers();
  }, [page, rowsPerPage, currentPageApi, totalSizeApi, pageSize]);

  const handleChangePage = (event, data) => {
    setPage(data);
  };
  const goToTheMutationPage = () => {
    window.location.href = "/pencipta/payment-mutation";
  };
  return (
    <Page className={classes.root} title="Payment">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                }}
              >
                Payment Transaction
              </Typography>
            </ThemeProvider>
          </Grid>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div
          style={{
            marginTop: "16px",
            border: "1px solid #D1D5DC",
            borderRadius: "10px",
            padding: "16px",
            width: "340px",
            backgroundColor: "#111827",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <img
                alt="Logo"
                src={
                  require("assets/image-public/images/icon_images/ri_money-dollar-circle-fill.svg")
                    .default
                }
              />
            </div>
            <div
              style={{
                marginLeft: "18px",
              }}
            >
              <div>
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "#9AA2B1",
                  }}
                >
                  Balance
                </span>
              </div>
              <div
                style={{
                  marginTop: "8px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "24px",
                    lineHeight: "32px",
                    color: "white",
                  }}
                >
                  <NumberFormat
                    displayType="text"
                    value={balance}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix="Rp"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              cursor: "pointer",
            }}
            onClick={goToTheMutationPage}
          >
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#111827",
              }}
            >
              See Balance Mutation
            </span>
            <ArrowForwardIcon style={{ color: "#9545EB" }} />
          </div>
        </div>

        <div
          style={{
            marginTop: "34px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <>
              <Button
                href="/pencipta/payment/add"
                style={{
                  backgroundColor: "#111827",
                  color: "white",
                  padding: "10px 28px 10px 28px",
                  marginRight: "10px",
                  width: "220px",
                }}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Create Transaction
                  </Typography>
                </ThemeProvider>
              </Button>
            </>
          </div>

          <div></div>
        </div>
        <Divider className={classes.divider} />

        {response && (
          <Results
            newLoading={loadingTable}
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={totalData}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
};

export default PaymentPencipta;
