import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordRounded";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Page, SelectInput, YearPicker } from "components";
import TableLoading from "../../../components/molecules/Table/TableLoading";
import { hardBaseUrl } from "../../../services/urlConstant";
import NumberFormat from "react-number-format";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const PenciptaReviewLagu = () => {
  const selectedCustomers = [];
  const [searchKey, setSearchKey] = useState("");
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [optionDsp, setOptionDsp] = useState([]);

  const [label, setLabel] = useState([]);
  const [dspInfo, setDspInfo] = useState();
  const optionOriginalPublisher = [];
  const [dspId, setDspId] = useState(
    localStorage.getItem("idDsp") ? localStorage.getItem("idDsp") : 0
  );

  const [nameOfDsp, setNameOfDsp] = useState("");
  const [publisherId, setPublisherId] = useState(
    localStorage.getItem("mainDsp") === null
      ? 0
      : localStorage.getItem("mainDsp")
  );
  const [trafficListenerPerMonth, setTrafficListenerPerMonth] = useState(0);
  const [royaltyPerMonth, setRoyaltyPerMonth] = useState(0);
  const [trafficListenerAll, setTrafficListenerAll] = useState(0);
  const [royaltyAll, setRoyaltyAll] = useState(0);
  const getColor = "";
  const [csvArray, setCsvArray] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const monthList = [
    {
      id: 1,
      name: "January",
    },
    {
      id: 2,
      name: "February",
    },
    {
      id: 3,
      name: "March",
    },
    {
      id: 4,
      name: "April",
    },
    {
      id: 5,
      name: "May",
    },
    {
      id: 6,
      name: "June",
    },
    {
      id: 7,
      name: "July",
    },
    {
      id: 8,
      name: "August",
    },
    {
      id: 9,
      name: "September",
    },
    {
      id: 10,
      name: "October",
    },
    {
      id: 11,
      name: "November",
    },
    {
      id: 12,
      name: "December",
    },
  ];
  const [monthName, setMonthName] = useState("select month");
  const [monthId, setMonthId] = useState(0);
  const [getMonth, setGetMonth] = useState("-");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(1);
  const [page, setPage] = useState(1);
  const [getYear, setGetYear] = useState(
    localStorage.getItem("year") === null
      ? moment().year()
      : localStorage.getItem("year")
  );
  const [yearList, setYearList] = useState([]);
  const [dspColor, setDspColor] = useState(
    localStorage.getItem("dspColor") === null
      ? ""
      : localStorage.getItem("dspColor")
  );
  const [outSideDspValue, setOutSideDspValue] = useState(0);

  const handleChangeSelectOutsideDsp = event => {
    setOutSideDspValue(event.target.value);
  };

  useEffect(() => {
    const thisYear = moment().year();
    for (let i = thisYear; i >= 2000; i--) {
      setYearList(yearList => [...yearList, i]);
    }
  }, []);

  const pagesCount = Math.ceil(totalData / rowsPerPage);

  useEffect(() => {
    getChartData();

    getDataTable();
  }, [dspId, publisherId, getYear, monthId, page, rowsPerPage]);

  const getDataTable = async () => {
    try {
      setLoadingTable(true);
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/composer/song-usage/dsp/data?dsp_id=${dspId}&page=${page}&size=${rowsPerPage}&year=${getYear}&month=${monthId}&search=${searchKey}`;

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);

      setTableData(res.data.data);
      setTotalData(res.data.meta.total);
      setPage(res.data.meta.page);
      setLoadingTable(false);
    } catch (error) {
      setLoadingTable(false);
    }
  };

  const getChartData = async () => {
    try {
      const token = localStorage.getItem("token");
      const url = `${hardBaseUrl}/composer/song-usage/dsp/chart?dsp_id=${dspId}&year=${getYear}`;

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(url, headers);

      setDspInfo(res.data.data[0].dsp.name);
      const newChartData = res.data.data[0].chart.map(item => ({
        listener: item.listener,
        royalty: item.royalty,
      }));
      setChartData([...newChartData]);

      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      const formatter2 = new Intl.NumberFormat("id-ID", {
        minimumFractionDigits: 0,
      });

      const sumEveryListener = res.data.data[0].chart.reduce(
        (acc, cur) => acc + cur.listener,
        0
      );
      setTrafficListenerAll(formatter2.format(sumEveryListener));

      const sumEveryRoyalty = res.data.data[0].chart.reduce(
        (acc, cur) => acc + cur.royalty,
        0
      );

      setRoyaltyAll(formatter.format(sumEveryRoyalty));

      const date = res.data.data[0].chart.map(data => data.date);

      const month = date.map(data => data.split("-")[1]);

      const year = date.map(data => data.split("-")[0]);

      const year2 = year.map(data => data.slice(-2));

      const getDate = data => {
        switch (data) {
          case "01":
            return "Jan";
          case "02":
            return "Feb";
          case "03":
            return "Mar";
          case "04":
            return "Apr";
          case "05":
            return "May";
          case "06":
            return "Jun";
          case "07":
            return "Jul";
          case "08":
            return "Aug";
          case "09":
            return "Sep";
          case "10":
            return "Oct";
          case "11":
            return "Nov";
          case "12":
            return "Dec";
          default:
            return "Jan";
        }
      };

      const monthYearArray = month.map((data, index) => {
        return `${getDate(data)} ${year2[index]}`;
      });

      setLabel(monthYearArray);
    } catch (error) {
      new Error(error);
    }
  };

  const getOptionDashboardNew = async () => {
    try {
      const token = localStorage.getItem("token");

      const url = `${hardBaseUrl}/composer/song-usage/dsp/option`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setOptionDsp(res.data.data.dsp);
    } catch (err) {
      new Error(err);
    }
  };

  useEffect(() => {
    getOptionDashboardNew();
    getDataTable();
    getChartData();
  }, [rowsPerPage, page, searchKey]);

  const handleChangeRowsPerPage = event => {
    event.preventDefault();
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={e => handleChangeRowsPerPage(e)}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const listPencipta = data => {
    let pencipta = data;

    if (pencipta === null) {
      return <div>-</div>;
    } else if (pencipta?.length < 3) {
      return pencipta.map(pencipta => (
        <Chip
          key={pencipta}
          label={pencipta}
          className={classes.tableChip}
          size="small"
        />
      ));
    } else if (pencipta?.length > 3) {
      let pencipta1 = pencipta.slice(0, 3);
      let pencipta2 = pencipta.slice(3, pencipta.length);
      return (
        <div>
          {pencipta1.map(pencipta => (
            <Chip
              key={pencipta}
              label={pencipta}
              className={classes.tableChip}
              size="small"
            />
          ))}
          <Chip
            label={"+" + pencipta2.length}
            className={classes.tableChip}
            size="small"
          />
        </div>
      );
    }
  };

  const filterList = event => {
    setPage(1);
    setSearchKey(event.target.value);
  };

  const handleChangeSelectDsp = event => {
    if (event.target.value === 0) {
      setDspId(0);

      setDspColor("");
    } else {
      setDspId(event.target.value);

      const nameDsp = optionDsp.filter(
        data => data.dsp_id === event.target.value
      );

      setNameOfDsp(nameDsp[0].name);
      setDspColor(nameDsp[0].color);
    }
  };

  const handleChangeSelectPublisher = event => {
    setPublisherId(event.target.value);
  };

  const data = {
    labels: label,
    datasets: [
      {
        label: dspInfo,
        data: chartData.map(data => {
          return data.listener;
        }),

        borderColor: dspColor,
        backgroundColor: "#54B371",
        fill: false,
        lineTension: 0.2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#54B371",
        pointBorderColor: "#54B371",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#54B371",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    parsing: {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      delimiter: ",",
      complete: function() {},
    },
    responsive: true,

    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },

    plugins: {
      datalabels: {
        formatter: function() {
          return "";
        },
      },
      layout: {},
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      subtitle: {
        display: false,
        text: "Custom Chart Subtitle",
      },
      tooltip: {
        enabled: false,
        external: context => {
          let tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          tooltipEl.classList.remove("below", "no-transform");

          const month = context.tooltip.title[0];

          let monthName = month.substring(0, 3);

          if (monthName === "Jan") {
            monthName = "JANUARY";
          } else if (monthName === "Feb") {
            monthName = "FEBRUARY";
          } else if (monthName === "Mar") {
            monthName = "MARCH";
          } else if (monthName === "Apr") {
            monthName = "APRIL";
          } else if (monthName === "May") {
            monthName = "MAY";
          } else if (monthName === "Jun") {
            monthName = "JUNI";
          } else if (monthName === "Jul") {
            monthName = "JULY";
          } else if (monthName === "Aug") {
            monthName = "AUGUST";
          } else if (monthName === "Sep") {
            monthName = "SEPTEMBER";
          } else if (monthName === "Oct") {
            monthName = "OCTOBER";
          } else if (monthName === "Nov") {
            monthName = "NOVEMBER";
          } else if (monthName === "Dec") {
            monthName = "DECEMBER";
          }

          if (monthName === "JANUARY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[0].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[0].royalty));
            setGetMonth("January");
          } else if (monthName === "FEBRUARY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[1].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[1].royalty));
            setGetMonth("February");
          } else if (monthName === "MARCH") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[2].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[2].royalty));
            setGetMonth("March");
          } else if (monthName === "APRIL") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[3].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[3].royalty));
            setGetMonth("April");
          } else if (monthName === "MAY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[4].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[4].royalty));
            setGetMonth("May");
          } else if (monthName === "JUNI") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[5].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[5].royalty));
            setGetMonth("Juni");
          } else if (monthName === "JULY") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[6].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[6].royalty));
            setGetMonth("July");
          } else if (monthName === "AUGUST") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[7].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[7].royalty));
            setGetMonth("August");
          } else if (monthName === "SEPTEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[8].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[8].royalty));
            setGetMonth("September");
          } else if (monthName === "OCTOBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[9].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[9].royalty));
            setGetMonth("October");
          } else if (monthName === "NOVEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[10].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[10].royalty));
            setGetMonth("November");
          } else if (monthName === "DECEMBER") {
            setTrafficListenerPerMonth(
              formatter2.format(chartData[11].listener)
            );
            setRoyaltyPerMonth(formatter.format(chartData[11].royalty));
            setGetMonth("December");
          }

          if (tooltipModel.body) {
            const dataMap = data => {
              let dataMapping = data
                .map(item => {
                  const getDataListener = data => {
                    if (monthName === "JANUARY") {
                      return data.data[0];
                    } else if (monthName === "FEBRUARY") {
                      return data.data[1];
                    } else if (monthName === "MARCH") {
                      return data.data[2];
                    } else if (monthName === "APRIL") {
                      return data.data[3];
                    } else if (monthName === "MAY") {
                      return data.data[4];
                    } else if (monthName === "JUNI") {
                      return data.data[5];
                    } else if (monthName === "JULY") {
                      return data.data[6];
                    } else if (monthName === "AUGUST") {
                      return data.data[7];
                    } else if (monthName === "SEPTEMBER") {
                      return data.data[8];
                    } else if (monthName === "OCTOBER") {
                      return data.data[9];
                    } else if (monthName === "NOVEMBER") {
                      return data.data[10];
                    } else if (monthName === "DECEMBER") {
                      return data.data[11];
                    }
                  };

                  const getDataRoyalty = data => {
                    if (monthName === "JANUARY") {
                      return data[0].royalty;
                    } else if (monthName === "FEBRUARY") {
                      return data[1].royalty;
                    } else if (monthName === "MARCH") {
                      return data[2].royalty;
                    } else if (monthName === "APRIL") {
                      return data[3].royalty;
                    } else if (monthName === "MAY") {
                      return data[4].royalty;
                    } else if (monthName === "JUNI") {
                      return data[5].royalty;
                    } else if (monthName === "JULY") {
                      return data[6].royalty;
                    } else if (monthName === "AUGUST") {
                      return data[7].royalty;
                    } else if (monthName === "SEPTEMBER") {
                      return data[8].royalty;
                    } else if (monthName === "OCTOBER") {
                      return data[9].royalty;
                    } else if (monthName === "NOVEMBER") {
                      return data[10].royalty;
                    } else if (monthName === "DECEMBER") {
                      return data[11].royalty;
                    }
                  };
                  const formatter = new Intl.NumberFormat("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  });

                  const formatter2 = new Intl.NumberFormat("id-ID", {
                    minimumFractionDigits: 0,
                  });

                  return `
                  <div style="margin-bottom:10px; ">
                    <div style="display: flex; padding-left: 1.2rem; ">
                      <div style="display: flex; margin-right: 10px;align-items: center;  ">
                        <div style="border-radius: 100%; background-color: ${
                          item.dataset.backgroundColor
                        }; height: 6px; width: 6px;"></div>
                        </div>
                  <div style="display: flex;  flex-direction: column;  font-family: 'Inter'; font-size: 12px; font-weight: bold">
                    ${item.dataset.label}
                  </div>
                  </div>
                  <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                  <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter';display: flex; margin-right: 1.2rem;align-items: center;">Traffic:</div>
                  <div style="color:#364052; font-size:12px; font-weight:500; font-family: 'Inter';display: flex;  flex-direction: column;  font-family: 'Inter';  margin-left: 5px; ">${formatter2.format(
                    getDataListener(item.dataset)
                  )}</div>
                  </div>

                  <div style="display: flex; flex-direction: row;  padding-left: 1.2rem;">
                  <div style="color:#687083; font-size:12px; font-weight:400; font-family: 'Inter'; display: flex; flex-direction: column; margin-right: 1.2rem;align-items: center;">Revenue:</div>
                  <div style="color:#364052; font-size:12px; font-weight:500; display: flex;  flex-direction: column;  font-family: 'Inter'; ">${formatter.format(
                    getDataRoyalty(chartData)
                  )},-</div>
                  </div>

                  </div>
              `;
                })
                .join("");
              return dataMapping;
            };
            const innerHtml = `
                      <div style="background-color: white;padding-bottom: 10px;width: 200px;  border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">

                          <div style=" padding-top: 5px; padding-bottom: 10px; padding-left: 1.2rem; color: #364052; font-family: 'Inter'; font-size: 12px; font-weight; 500;">
                             ${monthName} ${getYear}
                          </div>
                          ${dataMap(context.tooltip._tooltipItems)}
                       </div>
                  `;

            tooltipEl.querySelector("table").innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          if (monthName === "NOVEMBER" || monthName === "DECEMBER") {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.right =
              position.right + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          } else {
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + "px";
            tooltipEl.style.padding =
              tooltipModel.padding + "px " + tooltipModel.padding + "px";
            tooltipEl.style.pointerEvents = "none";
          }
        },
        backgroundColor: "#fff",
        titleFontColor: "#333",
        bodyColor: "#333",
        titleColor: "#333",
        footerColor: "#333",
        titleMarginBottom: 20,
        titleFont: {
          weight: "bold",
          size: 17,
        },
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 10,
        caretPadding: 50,
        mode: "index",
        intersect: false,
        bodySpacing: 5,
        borderColor: "#333",
        borderWidth: 1,
        bodyFont: {
          size: 10,
        },
        padding: {
          right: 45,
          left: 15,
          top: 15,
          bottom: 15,
        },
      },
    },
  };

  const handleChangeYear = value => {
    setGetYear(value);
  };
  const handleChangeMonth = event => {
    if (event.target.value === "select month") {
      setMonthName("select month");
      setMonthId(0);
      setPage(1);
    } else {
      setMonthName(event.target.value);
      setMonthId(event.target.value);
      setPage(1);
    }
  };
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  const formatter2 = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
  });

  const useStyles = makeStyles(theme => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    divider: {
      margin: theme.spacing(2, 0),
      borderTop: "1px solid #e1e1e1",
    },
    card: {
      borderLeft: "1px solid gray",
      boxShadow: "none",
      borderRadius: "0px",
    },
    insideCard: {
      padding: "20px",
    },
    cardArea: {
      marginTop: "20px",
      marginBottom: "20px",
      justifyContent: "space-between",
    },
    filterArea: {
      marginTop: "20px",
      marginBottom: "20px",
      display: "flex",
    },
    chipArea: {
      marginTop: "20px",
      marginBottom: "20px",
    },
    spotifyChip: {
      border: "1px solid #1db954",
      backgroundColor: "#ccffaa",
      color: "#1db954",
    },
    spotifyChipDot: {
      color: "#1db954",
    },
    tableChip: {
      marginRight: "10px",
    },
    pagination: {
      "& .Mui-selected": {
        backgroundColor: getColor || "black",
        color: "white",
      },
    },
    pageSize: {
      height: "30px",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
      paddingLeft: "5px",
      paddingRight: "5px",
      marginTop: "5px",
    },
    paginationText: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      color: "#687083",
    },
    textField: {
      width: "335px",
      height: "44px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    textField2: {
      width: "249px",
      height: "44px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    textFieldMonth: {
      width: "200px",
      height: "40px",
      backgroundColor: "white",
      borderRadius: "6px",
      border: "1px solid #D1D5DC",
    },
    btnCariLaporan: {
      backgroundColor: "black",
      color: "white",
      width: "156px",
      height: "40px",
      marginTop: "25px",
      marginLeft: "10px",
      marginRight: "10px",

      textTransform: "none",
      borderRadius: "6px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    headText: {
      fontSize: "12px",
      color: "#687083",
      fontWeight: "bold",
    },
    bodyTextBlack: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#111827",
      fontStyle: "normal",
    },
    bodyTextGrey: {
      fontSize: "14px",
      fontWeight: "regular",
      color: "#687083",
      fontStyle: "normal",
    },
    tableRow: {
      height: 20,
    },
    conTextBlack: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    dropdown: {
      color: "black",
    },
    subtitle: {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    btnRincian: {
      backgroundColor: "black",

      color: "white",
      padding: "10px 28px",
      borderRadius: "6px",
      width: "140px",
      height: "36px",

      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    reportText: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#687083",
      marginBottom: "15px",

      width: "300px",
    },
    reportValue: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#111827",
    },
    breadcrumbs: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#111827",
    },
    breadCrumbsActive: {
      fontSize: "14px",
      fontWeight: 400,
      fontStyle: "regular",
      color: "#687083",
    },
    label: {
      color: "#364052",
      fontSize: "14px",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "20px",
      marginBottom: "5px",
    },
    formControl: {
      height: "41px",
      backgroundColor: "#F7F7F7",
    },
    filterFormColor: {
      backgroundColor: "#F7F7F7",
    },
    placeholder: {
      "&::placeholder": {
        color: "#9AA2B1",
      },
    },
    table: {
      border: "1px solid #D1D5DC",
      borderRadius: "6px",
    },
  }));

  const getCsvArr = () => {
    const url = `${hardBaseUrl}/publisher/song-usage/download?year=${getYear}&dsp_id=${dspId}&size=${rowsPerPage}&page=${page}&original_publisher_id=${publisherId}&search&month=${monthId}`;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { headers })
      .then(res => {
        if (res.data.data !== null) {
          setCsvArray(res.data.data);
        } else {
          setCsvArray([]);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    getCsvArr();
  }, [getYear, dspId, publisherId, monthId, page, rowsPerPage]);
  const classes = useStyles();
  const typeWeb = localStorage.getItem("typeWeb");
  return (
    <div className="review-lagu">
      <Page className={classes.root} title="Song Usage Review">
        <Container maxWidth={false}>
          <Grid
            alignItems="flex-end"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <ThemeProvider theme={theme}>
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "32px",
                  }}
                >
                  Song Usage
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Typography className={classes.breadCrumbs}>Home</Typography>

                <Typography className={classes.breadCrumbsActive}>
                  Song Usage
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />
          <Card
            style={{
              backgroundColor: "#F9FAFB",
            }}
          >
            <CardContent>
              <Grid container spacing={1}>
                <Grid item>
                  <SelectInput
                    label="DSP"
                    value={dspId || ""}
                    placeholder="None"
                    onChange={handleChangeSelectDsp}
                    options={optionDsp}
                    optionKey="dsp_id"
                    optionLabel="name"
                    width={300}
                  />
                </Grid>
                {typeWeb === "caris" && (
                  <Grid item>
                    <SelectInput
                      label="Outside DSP"
                      value={""}
                      options={[{ id: "", label: "None" }]}
                      optionKey="id"
                      optionLabel="label"
                      width={350}
                    />
                  </Grid>
                )}
                <Grid item>
                  <YearPicker
                    label="Year"
                    onChange={handleChangeYear}
                    value={getYear}
                    width={300}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
              <ThemeProvider theme={theme}>
                <Typography
                  component="span"
                  style={{
                    fontSize: "18px",
                    lineHeight: "28px",
                    fontWeight: "700",
                    fontStyle: "normal",
                  }}
                >
                  Trend of Song Usage
                </Typography>
              </ThemeProvider>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
              <div
                style={{
                  border: "1px solid #ebebeb",
                  borderRadius: "5px",
                  paddingBottom: "50px",
                  paddingTop: "50px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                  }}
                >
                  {nameOfDsp === "" ? (
                    <div></div>
                  ) : (
                    <Chip
                      size="small"
                      label={nameOfDsp}
                      avatar={
                        <FiberManualRecordIcon
                          style={{ width: "10px", color: dspColor }}
                        />
                      }
                      style={{
                        marginRight: "10px",
                        color: dspColor,
                        backgroundColor: `${dspColor}10`,
                      }}
                    />
                  )}
                </div>
                <Line options={options} data={data} plugins={[]} />
              </div>
            </Grid>
          </Grid>
          <div className={classes.cardArea}>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <div className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >{`${getMonth} traffic`}</Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {trafficListenerPerMonth}
                    </Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <div className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >{`${getMonth} Revenue`}</Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {`${royaltyPerMonth},-`}
                    </Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <div className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >
                      Total Traffic
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {trafficListenerAll}
                    </Typography>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={3} md={3}>
                <Card className={classes.card}>
                  <div className={classes.insideCard}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.reportText}
                    >
                      Total Revenue
                    </Typography>
                    <Typography
                      component="h2"
                      variant="h3"
                      className={classes.reportValue}
                    >
                      {`${royaltyAll},-`}
                    </Typography>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "start",
            }}
          >
            <div>
              <Button
                variant="contained"
                className={classes.btnRincian}
                style={{
                  backgroundColor: getColor,
                  marginRight: "10px",
                  marginTop: "17px",
                }}
                component={Link}
                to={{
                  pathname: "/admin/review-lagu/print-report",
                  state: {
                    getMonth: getMonth,
                    getYear: getYear,
                    trafficListenerPerMonth: trafficListenerPerMonth,
                    trafficListenerAll: trafficListenerAll,
                    royaltyPerMonth: royaltyPerMonth,
                    royaltyAll: royaltyAll,
                    nameOfDsp: nameOfDsp,
                    chartData: data,
                    tableData: tableData,
                    dspColor: dspColor,
                  },
                }}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textTransform: "none",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Download PDF
                  </Typography>
                </ThemeProvider>{" "}
              </Button>
            </div>
            <div>
              <CSVLink data={csvArray} filename="song_usage">
                <Button
                  variant="contained"
                  className={classes.btnRincian}
                  style={{
                    backgroundColor: getColor,
                    marginRight: "10px",
                    marginTop: "17px",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Download CSV
                    </Typography>
                  </ThemeProvider>{" "}
                </Button>
              </CSVLink>
            </div>
            <div
              style={{
                marginLeft: "",
                marginTop: "5px",
              }}
            >
              <ThemeProvider theme={theme}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth={true}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    size="small"
                    id="demo-simple-select-outlined"
                    select
                    className={classes.textFieldMonth}
                    onChange={e => handleChangeMonth(e)}
                    value={monthName}
                    variant="outlined"
                    InputProps={{}}
                  >
                    <MenuItem value={"select month"}>
                      <em>Select Month</em>
                    </MenuItem>
                    {monthList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </ThemeProvider>
            </div>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <TextField
                id="fliterText"
                name="filterText"
                size="small"
                style={{
                  width: "200px",

                  border: "1px solid #D1D5DC",
                  borderRadius: "6px",
                  marginBottom: "20px",
                }}
                onChange={e => filterList(e)}
                value={searchKey}
                margin="normal"
                type="text"
                variant="outlined"
                placeholder="Song title, Composer"
                InputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "#9AA2B1" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            {loadingTable ? (
              <>
                <Card>
                  <CardContent>
                    <TableLoading
                      open={loadingTable}
                      backdropStyle={{
                        color: "#fff",
                        zIndex: 100,
                        position: "relative",
                      }}
                    />
                  </CardContent>
                </Card>
              </>
            ) : (
              <>
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <ThemeProvider theme={theme}>
                        <TableCell align="center"></TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Song Title
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            ISWC
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Composer
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                          >
                            Traffic
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.headText}
                            align="right"
                          >
                            Revenue
                          </Typography>
                        </TableCell>
                      </ThemeProvider>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.length > 0 ? (
                      tableData.map(lagu => (
                        <TableRow
                          hover
                          key={lagu.song_id}
                          selected={
                            selectedCustomers.indexOf(lagu.song_id) !== -1
                          }
                        >
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              className={classes.btnRincian}
                              style={{
                                backgroundColor: getColor,
                              }}
                              component={Link}
                              to={{
                                pathname: `/admin/review-lagu/${lagu.song_id}`,
                                state: {
                                  lagu: lagu,
                                  dsp_id: dspId,
                                  year: getYear,
                                  month: [monthId],
                                },
                              }}
                            >
                              <ThemeProvider theme={theme}>
                                <Typography
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    textTransform: "none",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  See Detail
                                </Typography>
                              </ThemeProvider>{" "}
                            </Button>
                          </TableCell>
                          <ThemeProvider theme={theme}>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {lagu.title}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {lagu.isrc_code}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {listPencipta(lagu.composer_names)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                {formatter2.format(lagu.listener)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography
                                variant="subtitle2"
                                className={classes.bodyTextBlack}
                              >
                                <NumberFormat
                                  displayType="text"
                                  value={lagu.base_currency_revenue}
                                  thousandSeparator={true}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  prefix="Rp"
                                />
                              </Typography>
                            </TableCell>
                          </ThemeProvider>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} style={{ height: "50px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ThemeProvider theme={theme}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: "400",
                                  fontStyle: "normal",
                                  color: "#687083",
                                }}
                              >
                                Tidak ada data
                              </Typography>
                            </ThemeProvider>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </>
            )}
            <div className={classes.paginationArea}>
              {tableData?.length > 0 ? (
                <Grid
                  alignItems="flex-end"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <ThemeProvider theme={theme}>
                      <Typography className={classes.paginationText}>
                        <div style={{ display: "flex" }}>
                          <p style={{ marginTop: "7px" }}>Item per halaman:</p>{" "}
                          <div style={{ marginLeft: "10px" }}>
                            {pageSizePagination()}
                          </div>
                        </div>
                      </Typography>
                    </ThemeProvider>
                  </Grid>

                  <Grid item>
                    <Pagination
                      shape="rounded"
                      className={classes.pagination}
                      page={page}
                      count={pagesCount}
                      onChange={(event, value) => {
                        setPage(value);
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
            </div>
          </Grid>
        </Container>
      </Page>
    </div>
  );
};

export default PenciptaReviewLagu;
