import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Page } from "components";
import { hardBaseUrl } from "../../../services/urlConstant";
import Results from "./Results";

const theme = createTheme({
  typography: {
    fontFamily: ["Inter"].join(","),
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),

    paddingBottom: theme.spacing(3),
  },

  results: {
    marginTop: theme.spacing(3),
  },

  divider: {
    margin: theme.spacing(2, 0),

    borderTop: "1px solid #e1e1e1",
  },

  formControl: {},

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,

    color: "#fff",
  },

  pagination: {
    "& .Mui-selected": {
      backgroundColor: "black",

      color: "white",
    },
  },

  pageSize: {
    height: "30px",

    borderRadius: "6px",

    border: "1px solid #D1D5DC",

    paddingLeft: "5px",

    paddingRight: "5px",

    marginTop: "5px",
  },

  paginationText: {
    fontSize: "14px",

    fontWeight: "normal",

    fontStyle: "normal",

    color: "#687083",
  },

  breadcrumbs: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#111827",
  },

  breadCrumbsActive: {
    fontSize: "14px",

    fontWeight: 400,

    fontStyle: "regular",

    color: "#687083",
  },
  mypadding2: {
    "& .MuiSelect-outlined": {
      paddingTop: "15.5px",
      paddingBottom: "10.5px",
    },
    width: "200px",
  },
  placeholder: {
    "&::placeholder": {
      color: "#9AA2B1",
    },
  },
}));

const LoanPage = () => {
  const classes = useStyles();

  const composerOptions = "";

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [response, setResponse] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const listStatus = [
    {
      id: 1,
      name: "Waiting for Approval",
      value: "waiting_for_approval",
    },
    {
      id: 2,
      name: "Approved",
      value: "approved",
    },
    {
      id: 3,
      name: "Reject",
      value: "reject",
    },
    {
      id: 4,
      name: "Active",
      value: "active",
    },
    {
      id: 5,
      name: "Paid",
      value: "paid",
    },
  ];

  const handleChangeRowsPerPage = event => {
    event.preventDefault();

    setRowsPerPage(event.target.value);

    setPage(1);
  };

  const handleChangeSearch = event => {
    event.preventDefault();

    setSearchKey(event.target.value);
  };

  const pageSizePagination = () => {
    return (
      <div className={classes.pageSize}>
        <select
          style={{
            backgroundColor: "transparent",

            border: "none",

            outline: "none",

            marginTop: "4px",
          }}
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 50].map(pageSize => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const handleChangePage = (event, data) => {
    setPage(data);
  };

  const getResponse = () => {
    setLoadingTable(true);
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/composer/transaction/loan?page=${page}&per_page=${rowsPerPage}&filter_status=${statusFilter}&order_by=publisher_transaction_id&order=desc&search=${searchKey}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setResponse(res.data.data.loan_data);

        setTotalPage(res.data.data.query.total_page);

        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };
  const getResponseSearch = () => {
    setLoadingTable(true);
    const token = localStorage.getItem("token");

    const url = `${hardBaseUrl}/composer/transaction/loan?page=1&per_page=${rowsPerPage}&filter_status=${statusFilter}&order_by=publisher_transaction_id&order=desc&search=${searchKey}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setResponse(res.data.data.loan_data);

        setTotalPage(res.data.data.query.total_page);

        setLoadingTable(false);
      })
      .catch(() => {
        setLoadingTable(false);
      });
  };

  const getComposerList = () => {
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/publisher/transaction-composer`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    getResponse();
    getComposerList();
  }, [page, rowsPerPage]);
  useEffect(() => {
    setPage(1);
    getResponseSearch();
    getComposerList();
  }, [composerOptions, searchKey, statusFilter]);

  const handleChangeStatusFilter = event => {
    setStatusFilter(event.target.value);
  };
  return (
    <Page className={classes.root} title="Loan">
      <Container maxWidth={false}>
        <Grid
          alignItems="flex-end"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <ThemeProvider theme={theme}>
              <Typography
                style={{
                  fontSize: "24px",

                  fontWeight: "700",

                  lineHeight: "32px",
                }}
              >
                Loan Transaction
              </Typography>
            </ThemeProvider>
          </Grid>

          <Grid item>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.breadCrumbs}>Home</Typography>

              <Typography className={classes.breadCrumbs}>
                Transaction
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div
          style={{
            display: "flex",

            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <>
              <Button
                href="/pencipta/loan/add"
                style={{
                  backgroundColor: "#111827",

                  color: "white",

                  padding: "10px 28px 10px 28px",

                  marginRight: "10px",

                  width: "300px",
                }}
                variant="contained"
                size="large"
                startIcon={<AddIcon />}
              >
                <ThemeProvider theme={theme}>
                  <Typography
                    component="span"
                    style={{
                      fontSize: "14px",

                      lineHeight: "20px",

                      fontWeight: "500",

                      fontStyle: "normal",

                      textTransform: "none",

                      whiteSpace: "nowrap",
                    }}
                  >
                    Create Transaction
                  </Typography>
                </ThemeProvider>
              </Button>
            </>

            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <OutlinedInput
                placeholder="Pencarian"
                id="outlined-adornment-amount"
                style={{
                  height: "44px",

                  border: "1px solid #D1D5DC",

                  borderRadius: "6px",
                }}
                value={searchKey}
                onChange={event => handleChangeSearch(event)}
                inputProps={{
                  classes: {
                    input: classes.placeholder,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#9AA2B1" }} />
                  </InputAdornment>
                }
                fullWidth={false}
              />
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            {}

            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  shrink={false}
                  style={{
                    marginRight: "-5px",
                    marginTop: "-8px",
                  }}
                >
                  {statusFilter !== "" ? null : "All"}
                </InputLabel>
                <Select
                  className={classes.mypadding2}
                  id="demo-simple-select-outlined"
                  value={statusFilter}
                  onChange={handleChangeStatusFilter}
                  autoWidth={true}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {listStatus.map(item => (
                    <MenuItem value={item.value} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <Divider className={classes.divider} />

        {response && (
          <Results
            newLoading={loadingTable}
            className={classes.results}
            items={response}
            pageSizePagination={pageSizePagination()}
            totalData={totalPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        )}
      </Container>
    </Page>
  );
};

export default LoanPage;
