import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import { useDispatch, useSelector } from "react-redux";
import navConfigAdmin from "./navConfig/navConfigAdmin";
import navConfigPublisher from "./navConfig/navConfigPublisher";
import { hardBaseUrl } from "../../services/urlConstant";
import axios from "axios";
import Swal from "sweetalert2";
import navConfig from "./navConfig";
import navConfigAssoc from "../DashboardPublisher/navConfig/navConfigAssoc";
import navConfigOther from "../DashboardPublisher/navConfig";
import navConfigPencipta from "./navConfigPencipta";
import { setPublisherId } from "../../constants/landingPage/publisherId";
import { setKonfigurasiMenu } from "constants/landingPage/konfigurasiMenu";
import * as Sentry from "@sentry/react";
import { getErrors } from "utils";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "white",
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  content2: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    container:
      "swal2-container swal2-center swal2-backdrop-show swal2-container-me",
  },
  showClass: {
    backdrop: "swal2-backdrop-show",
  },
  buttonsStyling: true,
});

const getHrefByMenu = menu => {
  const hrefMap = {
    System: { id: 1, href: "/admin/konfigurasi/sistem" },
    "Notification Template": { id: 2, href: "/admin/konfigurasi/template-notifikasi" },
    Role: { id: 4, href: "/admin/konfigurasi/role-user" },
    Activity: { id: 5, href: "/admin/konfigurasi/aktifitas" },
    Publisher: { id: 6, href: "/admin/parameter/publisher" },
    User: { id: 7, href: "/admin/parameter/user" },
    "User Publisher": { id: 8, href: "/admin/parameter/user-publisher" },
    Composer: { id: 9, href: "/admin/parameter/pencipta" },
    DSP: { id: 10, href: "/admin/parameter/dsp" },
    Bank: { id: 11, href: "/admin/parameter/bank-admin" },
    "Finalization of News & Ads": { id: 12, href: "/admin/finalisasi-berita" },
    "List of News & Ads": { id: 13, href: "/admin/news-ads" },
    Dashboard: { id: 14, href: "/admin/dashboard" },
    "Song Usage": { id: 15, href: "/admin/review-lagu" },
    Personalization: { id: 16, href: "/admin/konfigurasi/personalisasi" },
    Payment: { id: 17, href: "/admin/konfigurasi/pembayaran" },
    "Original Publisher": { id: 18, href: "/admin/konfigurasi/original-publisher" },
    "Composer/Author": { id: 20, href: "/admin/parameter/pencipta" },
    Performer: { id: 21, href: "/admin/parameter/performer" },
    Song: { id: 22, href: "/admin/parameter/lagu" },
    Client: { id: 23, href: "/admin/parameter/dsp" },
    User2: { id: 24, href: "/admin/parameter/user" },
    "Composer Contract": { id: 25, href: "/admin/kontrak-composer" },
    "News & Ads": { id: 26, href: "/admin/berita-publisher" },
    "Web Management": { id: 27, href: "/admin/manajemen-web" },
    Song2: { id: 31, href: "/admin/parameter/lagu" },
    Dashboard3: { id: 32, href: "/admin/dashboard" },
    Contract: { id: 33, href: "/admin/kontrak" },
    "User Composer": { id: 35, href: "/admin/parameter/user-pencipta" },
    "Original Publisher Contract": { id: 36, href: "/admin/konfigurasi/original-publisher-contract" },
    Advanced: { id: 37, href: "/admin/advance" },
    Invoice: { id: 38, href: "/admin/invoice" },
    Loan: { id: 40, href: "/admin/loan" },
    Invoice2: { id: 45, href: "/admin/invoice" },
    "Double Claim": { id: 46, href: "/admin/publisher/double-claim" },
    Unclaim: { id: 49, href: "/admin/publisher/song-unclaim" },
    Report: { id: 50, href: "/admin/reports" },
    "Cost Allocation": { id: 53, href: "/admin/konfigurasi/cost-allocation" },
    Billing: { id: 54, href: "/admin/billing/publisher" },
    Agent: { id: 56, href: "/admin/parameter/agent" },
    Tax: { id: 57, href: "/admin/konfigurasi/pajak" },
    "Cost Type": { id: 58, href: "/admin/konfigurasi/cost-type" },
    "Billing Configuration": { id: 59, href: "/admin/konfigurasi/billing-configuration" },
    Association: { id: 60, href: "/admin/parameter/association" },
    Currency: { id: 62, href: "/admin/parameter/currency" },
    "Upload Data": { id: 63, href: "/admin/upload-data" },
    "Exchange Rate": { id: 64, href: "/admin/konfigurasi/exchange-rate" },
    "Upload Monitoring": { id: 65, href: "/admin/upload-monitoring" },
    Group: { id: 67, href: "/admin/parameter/group" },
    "Client Classification": { id: 68, href: "/admin/parameter/client-classification" },
    "Sub Reporting": { id: 73, href: "/admin/sub-reporting" },
    "Revenue Type": { id: 74, href: "/admin/parameter/admin-revenue-type" },
    "DSP Revenue Type": { id: 75, href: "/admin/konfigurasi/dsp-type-revenue" },
    Country: { id: 76, href: "/admin/parameter/admin-country" },
    "Landing Page": { id: 78, href: "/admin/landing-page" },
    "Download Monitoring": { id: 80, href: "/admin/download-monitoring" },
    "Download CCID": { id: 81, href: "/admin/download-ccid" },
    "DSP Usage Process": { id: 82, href: "/admin/usage-process" },
    "Announcement Management": { id: 83, href: "/admin/konfigurasi/announcement-management" },
    "Our Partner": { id: 84, href: "/admin/our-partner" },
    "User Testimony": { id: 85, href: "/admin/user-testimony" },
    "Blog Posting": { id: 86, href: "/admin/blog-posting" },
    "Init Catalogue": { id: 89, href: "/admin/init-catalogue" },
    Society: { id: 90, href: "/admin/parameter/society" },
    Territory: { id: 91, href: "/admin/parameter/territory" },
    "Download Monitoring2": { id: 92, href: "/admin/download-monitoring" },
    "User Society": { id: 93, href: "/admin/parameter/user-society" },
    "Song Usage4": { id: 95, href: "/admin/review-lagu" },
    Activity3: { id: 96, href: "/admin/konfigurasi/aktifitas" },
    "Submit to DSP": { id: 106, href: "/admin/submitDSP" },
    "Sub Reporting Approval": { id: 116, href: "/admin/sub-reporting-approval" },
    "Unclaim Monitoring": { id: 117, href: "/admin/publisher/unclaim-monitoring" },
    Footer: { id: 119, href: "/admin/footer" },
    "User Testimoni": { id: 123, href: "/admin/user-testimoni" },
  };
  return hrefMap[menu] || null;
};

function DashboardAdmin(props) {
  const classes = useStyles();
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const { konfigurasiMenu } = useSelector(state => state?.konfigurasiMenu);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [loadingComp, setLoadingComp] = useState(false);
  const [userLetter, setUserLetter] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationUpdate, setNotificationUpdate] = useState(false);
  const [notificationTotal, setNotificationTotal] = useState(0);
  const [userLogin, setUserLogin] = useState(null);
  const [afterGetMe, setAfterGetMe] = useState(false);
  const [calculateMenus, setCalculateMenus] = useState(false);
  const [aterCalculateMenus, setAterCalculateMenus] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleId = localStorage?.getItem("role_id");
        const token = localStorage.getItem("token");

        setLoadingComp(true);

        const urlMe = `${hardBaseUrl}/me${roleId ? `/${roleId}` : ""}`;
        const resultMe = await axios.get(urlMe, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const userData = resultMe?.data?.data;
        setUserLogin(userData);
        setAfterGetMe(true);

        if (!roleId) {
          localStorage.setItem("role_id", userData?.role_id);
        }

        const urlNotifications = `${hardBaseUrl}/notification?page=1&size=10`;
        const responseNotifications = await axios.get(urlNotifications, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setNotifications(responseNotifications.data.data);

        if (notificationUpdate) {
          const responseUpdatedNotifications = await axios.get(
            urlNotifications,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          setNotifications(responseUpdatedNotifications.data.data);
          setNotificationUpdate(false);
        }

        const urlNotificationTotal = `${hardBaseUrl}/notification/unread`;
        const responseNotificationTotal = await axios.get(
          urlNotificationTotal,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setNotificationTotal(responseNotificationTotal.data.data);
      } catch (error) {
        handleFetchError(error);
      }
    };

    fetchData();
  }, []);

  const handleFetchError = error => {
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed!",
        text:
          error?.response?.data?.errors[0]?.message || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result?.isDismissed) {
          history.push("/");
        }
      });
  };

  useEffect(() => {
    if (afterGetMe) {
      try {
        handleUserLoginRole();
      } catch (error) {
        handleRoleError(error);
      }
    }
  }, [afterGetMe]);

  const handleUserLoginRole = () => {
    if (
      userLogin.role.type === "publisher" ||
      userLogin.role.type === "composer"
    ) {
      dispatch(setPublisherId(userLogin.publisher.publisher_id));
    }
    setUserLetter("M");
    setCalculateMenus(true);
    setAfterGetMe(false);
  };

  const handleRoleError = error => {
    Sentry.captureMessage("Error afterGetMe: " + error.message);
    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: "Failed After Get Me!",
        text: error?.response || "Something went wrong",
        showCloseButton: false,
      })
      .then(result => {
        if (result.isConfirmed) {
          setCalculateMenus(true);
          setAfterGetMe(false);
        }
      });
  };

  useEffect(() => {
    if (calculateMenus) {
      try {
        configureMenus();
      } catch (err) {
        handleMenuError(err);
      }

      setLoadingComp(false);
      setCalculateMenus(false);
      setAterCalculateMenus(true);
    }
  }, [calculateMenus]);

  const configureMenus = () => {
    const getIcon = [...navConfigOther, ...navConfigAssoc, ...navConfigPublisher, ...navConfigPencipta, ...navConfig, ...navConfigAdmin]
      .flatMap((item) => item?.items || [])
      .filter((item, index, array) => array.findIndex(i => i.title === item.title) === index);

    console.log({ getIcon });
    const isParameter = (string) => string === "Master" ? "Parameter" : string;
    const newMenu = userLogin?.user_menu?.filter(({ is_active }) => is_active)?.map(({ menu_text, children, id }) => ({
      href: getHrefByMenu(isParameter(menu_text)?.href),
      show: true,
      id,
      icon: getIcon.find(({ title }) => title === isParameter(menu_text))?.icon,
      selectedIcon: getIcon.find(({ title }) => title === isParameter(menu_text))?.selectedicon,
      title: menu_text === "Master" ? "Parameter" : menu_text,
      children: (children || [])?.filter((item) => item?.is_active)?.map((item) => ({
        href: getHrefByMenu(item?.menu_text)?.href,
        show: true,
        title: item?.menu_text,
        id: item?.id
      }))
    }));
    dispatch(setKonfigurasiMenu(newMenu));
  };

  const handleMenuError = err => {
    Sentry.captureMessage("Error calculateMenus: " + err.message);
    swalWithBootstrapButtons.fire({
      icon: "error",
      title: "Failed Calculate Menu!",
      text: err?.message || "Error",
      showCloseButton: false,
    });
  };

  const handleMarkAsRead = async (e, id) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const url = `${hardBaseUrl}/notification/${id}`;

    try {
      await axios.put(
        url,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotificationUpdate(true);
    } catch (error) {
      let errMessage = getErrors(error.response);
      Sentry.captureMessage("Error catch update notif: " + errMessage);
    }
  };

  // this condition probably wrong
  const navigationCondition =
    location.pathname !== "/admin/manajemen-web/preview" &&
    location.pathname === "/admin/berita-publisher/tambah/preview" ||
    location.pathname === "/admin/berita-publisher/edit/preview" ||
    location.pathname === "/admin/berita/tambah/preview" ||
    location.pathname === "/admin/berita/edit/preview" ||
    location.pathname === "/admin/finalisasi-berita/view/preview";
  return (
    <>
      {!navigationCondition && (
        <>
          <TopBar
            userProfileImage={userLogin?.display_picture_url}
            theRole={userLogin?.role?.type}
            nameOfRole={userLogin?.role?.name}
            theName={userLogin?.publisher?.name}
            imgOfPublisher={userLogin?.publisher?.profile_image}
            onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
            loadingComponent={loadingComp}
            userFirstLetter={userLetter}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
            notificationStatus={notificationTotal}
            userLogin={userLogin}
          />
          <NavBar
            theColor={userLogin?.publisher?.theme_color}
            onMobileClose={() => setOpenNavBarMobile(false)}
            openMobile={openNavBarMobile}
            loadingComponent={loadingComp}
            selectedNavItems={konfigurasiMenu || []}
            userLogin={userLogin}
          />
        </>
      )}

      {aterCalculateMenus && (
        <div className={classes.container}>
          {[
            "/admin/berita-publisher/tambah/preview",
            "/admin/berita-publisher/edit/preview",
            "/admin/berita/tambah/preview",
            "/admin/berita/edit/preview",
            "/admin/finalisasi-berita/view/preview",
            "/admin/manajemen-web/preview",
          ].includes(location.pathname) ? (
            <div className={classes.content2}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, { userLogin })}
              </Suspense>
            </div>
          ) : (
            <div className={classes.content}>
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(props.route.routes, {
                  userLogin,
                  userRole: userLogin?.role?.type,
                  rolesFor: userLogin?.role?.roles_for,
                  publisherChoosen: userLogin?.publisher,
                })}
              </Suspense>
            </div>
          )}
        </div>
      )}
    </>
  );
}

DashboardAdmin.propTypes = {
  route: PropTypes.object,
};

export default DashboardAdmin;
