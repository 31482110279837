import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import axios from "axios";
import {
  AutoCompleteWithSearch,
  FormLabel,
  ModalError,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "components";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { hardBaseUrl } from "services/urlConstant";
import { getErrors } from "utils";

const ModalPerformer = ({
  open,
  onClose,
  preload,
  handleSubmit,
  selectedOptions,
}) => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 10,
    search: "",
  });
  const [options, setOptions] = useState([]);
  const [selectedPerformer, setSelectedPerformer] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isrc, setIsrc] = useState("");
  const [dataError, setDataError] = useState(null);
  let buttonDisabled = !selectedPerformer.length;

  const handleChangeQueryParams = (value, key) => {
    setQueryParams(currentState => ({
      ...currentState,
      ...(key !== "page" && { page: 1 }),
      [key]: value,
    }));
  };
  const isISRCExists = isrc => {
    if (preload && preload.isrc_code === isrc) {
      return false;
    }
    return (
      Boolean(selectedOptions.length) &&
      selectedOptions.some(option => option.isrc_code === isrc)
    );
  };

  const getOptionPerformer = async () => {
    try {
      setLoadingPage(true);
      const res = await axios.get(`${hardBaseUrl}/performers`, {
        headers,
        params: queryParams,
      });
      const { data, meta } = res?.data;
      const newOptions = data?.map(item => ({
        ...item,
        id: item?.performer_id,
      }));
      setOptions(prev =>
        queryParams.page === 1 ? newOptions : [...prev, ...newOptions]
      );
      setHasMore(meta?.total > newOptions.length);
    } catch (error) {
      ModalError(getErrors(error?.response));
    } finally {
      setLoadingPage(false);
    }
  };
  const debounceOptionPerformer = useCallback(
    debounce(() => {
      getOptionPerformer();
    }, 500),
    [queryParams]
  );
  const getPreload = () => {
    setIsrc(preload?.isrc_code || "");
    setSelectedPerformer(preload?.performers || []);
  };

  const onSubmit = () => {
    if (isISRCExists(isrc)) {
      setDataError("ISRC code already exists in the selected options.");
    } else {
      handleSubmit({
        isrc_code: isrc,
        performers: selectedPerformer,
      });
      setIsrc("");
      setSelectedPerformer([]);
      setDataError(null);
      onClose();
    }
  };

  useEffect(() => {
    if (queryParams.search) {
      debounceOptionPerformer();
      return () => {
        debounceOptionPerformer.cancel();
      };
    } else {
      getOptionPerformer();
    }
  }, [queryParams, debounceOptionPerformer]);
  useEffect(() => {
    getPreload();
  }, [preload]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{preload ? "Update" : "Add"} Performer</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" rowSpacing={1}>
          <Grid item>
            <FormLabel label="ISRC Code" />
            <TextInput
              placeholder="ISRC Code"
              value={isrc}
              onChange={e => setIsrc(e?.target?.value)}
              error={!!dataError}
              helperText={dataError}
            />
          </Grid>
          <Grid item>
            <FormLabel label="Performers Name" />
            <AutoCompleteWithSearch
              multiple
              loading={loadingPage}
              disableCloseOnSelect
              value={selectedPerformer}
              options={options}
              onChange={item => setSelectedPerformer(item)}
              inputValue={queryParams?.search}
              onInputChange={value => handleChangeQueryParams(value, "search")}
              optionLabel="first_name"
              placeholder="Performers Name"
              setQueryParams={setQueryParams}
              hasMore={hasMore}
              width="100%"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SecondaryButton label="Cancel" onClick={onClose} />
        <PrimaryButton
          label={preload ? "Update" : "Add"}
          onClick={onSubmit}
          disabled={buttonDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalPerformer;
